<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    class="demo-ruleForm"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="project_name">
          <el-input v-model="ruleForm.project_name" :disabled="true">
            <template slot="prepend">案件名</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="customer_name">
          <el-input v-model="ruleForm.customer_name" :disabled="true">
            <template slot="prepend">得意先名</template>
          </el-input>
        </el-form-item>
      </el-col>
      </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-col :span="10">商品名/摘要</el-col>
            <el-col :span="4">数量</el-col>
            <el-col :span="10">備考</el-col>
          </div>
          <div>
            <el-row :gutter="0">
              <el-col :span="10">
                <el-form-item prop="deliverables1">
                  <el-input v-model="ruleForm.deliverables1"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="quantity1">
                  <el-input v-model="ruleForm.quantity1"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="memo1">
                  <el-input v-model="ruleForm.memo1"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="deliverables2">
                  <el-input v-model="ruleForm.deliverables2"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="quantity2">
                  <el-input v-model="ruleForm.quantity2"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="memo2">
                  <el-input v-model="ruleForm.memo2"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="deliverables3">
                  <el-input v-model="ruleForm.deliverables3"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="quantity3">
                  <el-input v-model="ruleForm.quantity3"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="memo3">
                  <el-input v-model="ruleForm.memo3"> </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <br />
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="" prop="remarks">
          <div>備考</div>
          <el-input v-model="ruleForm.remarks" type="textarea" autosize>
            <template slot="prepend">※(注)</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="20" justify="end">
      <el-col :span="2"><p>以上</p></el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item>
          <el-button-group>
            <el-button
              type="warning"
              @click="cancelForm()"
              icon="el-icon-document-delete"
              plain
              >閉じる</el-button
            >
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              icon="el-icon-document-checked"
              plain
              >登録・更新</el-button
            >
          </el-button-group>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { getEstimateDataOne, getEstimateDetailsDataAll } from "api/estimates";
import {
  postDeliveryData,
  putDeliveryData,
  getDeliveryDataOne,
} from "api/delivery";
import { Message } from "element-ui";

export default {
  name: "DeliveryForm",
  components: {},
  props: {
    cDeliveryForm: {
      type: Object,
      defaylt: function () {
        return {
          project_name: "",
          project_code: "",
          customer_name: "",
          estimate_code: "",
          delivery_code: "",
          method: "",
        };
      },
    },
  },
  data() {
    return {
      ruleForm: {
        delivery_code: this.cDeliveryForm.delivery_code,
        project_name: this.cDeliveryForm.project_name,
        project_code: this.cDeliveryForm.project_code,
        estimate_code: this.cDeliveryForm.estimate_code,
        customer_name: this.cDeliveryForm.customer_name,
        deliverables1: "",
        deliverables2: "",
        deliverables3: "",
        quantity1: "",
        quantity2: "",
        quantity3: "",
        memo1: "",
        memo2: "",
        memo3: "",
        remarks: "",
      },
      rules: {
        customer_name: [
          {
            required: true,
            message: "得意先名を入力してください",
            trigger: "change",
          },
        ],
        CustomerAddress: [
          {
            required: true,
            message: "得意先住所を入力してください",
            trigger: "blur",
          },
        ],
        Work: [
          {
            required: true,
            message: "作業内容を入力してください",
            trigger: "blur",
          },
        ],
        WorkTime: [
          {
            required: true,
            message: "作業期間を入力してください",
            trigger: "blur",
          },
        ],
        PersonnelI: [
          {
            required: true,
            message: "主任担当者(甲)を入力してください",
            trigger: "blur",
          },
        ],
        PersonnelII: [
          {
            required: true,
            message: "主任担当者(乙)を入力してください",
            trigger: "blur",
          },
        ],
        Deliverables: [
          {
            required: true,
            message: "成果物を入力してください",
            trigger: "blur",
          },
        ],
        DeliverableSpace: [
          {
            required: true,
            message: "納入場所を入力してください",
            trigger: "blur",
          },
        ],
        Commission: [
          {
            required: true,
            message: "委託料を入力してください",
            trigger: "blur",
          },
        ],
        PaymentDate: [
          {
            required: true,
            message: "支払期日を入力してください",
            trigger: "blur",
          },
        ],
        AcceptanceConditions: [
          {
            required: true,
            message: "検収条件を入力してください",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created: function () {
    if (this.cDeliveryForm.method === "put") {
      this.getDeliveryDataOne();
    } else {
      this.getEstimateDataOne();
    }
    this.getEstimateDetailsDataAll();
  },
  methods: {
    getEstimateDataOne() {
      let values = {};
      values["estimate_code"] = this.cDeliveryForm.estimate_code;
      getEstimateDataOne(values)
        .then((res) => {
          console.log(res);
          this.ruleForm.deliverables1 = res.data.data[0].deliverables1;
          this.ruleForm.deliverables2 = res.data.data[0].deliverables2;
          this.ruleForm.deliverables3 = res.data.data[0].deliverables3;
          this.ruleForm.quantity1 = res.data.data[0].quantity1;
          this.ruleForm.quantity2 = res.data.data[0].quantity2;
          this.ruleForm.quantity3 = res.data.data[0].quantity3;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEstimateDetailsDataAll() {
      let values = {};
      values["estimate_code"] = this.cDeliveryForm.estimate_code;
      getEstimateDetailsDataAll(values)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDeliveryDataOne() {
      let values = {};
      values["delivery_code"] = this.cDeliveryForm.delivery_code;
      getDeliveryDataOne(values)
        .then((res) => {
          this.ruleForm.delivery_code = res.data.data[0].delivery_code;
          this.ruleForm.project_name = res.data.data[0].project_name;
          this.ruleForm.project_code = res.data.data[0].project_code;
          this.ruleForm.estimate_code = res.data.data[0].estimate_code;
          this.ruleForm.customer_name = res.data.data[0].customer_name;
          this.ruleForm.deliverables1 = res.data.data[0].deliverables1;
          this.ruleForm.deliverables2 = res.data.data[0].deliverables2;
          this.ruleForm.deliverables3 = res.data.data[0].deliverables3;
          this.ruleForm.quantity1 = res.data.data[0].quantity1;
          this.ruleForm.quantity2 = res.data.data[0].quantity2;
          this.ruleForm.quantity3 = res.data.data[0].quantity3;
          this.ruleForm.memo1 = res.data.data[0].memo1;
          this.ruleForm.memo2 = res.data.data[0].memo2;
          this.ruleForm.memo3 = res.data.data[0].memo3;
          this.ruleForm.remarks = res.data.data[0].remarks;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    postDeliveryData() {
      let values = {};
      values["delivery_code"] = this.ruleForm.delivery_code;
      values["estimate_code"] = this.ruleForm.estimate_code;
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["deliverables1"] = this.ruleForm.deliverables1;
      values["deliverables2"] = this.ruleForm.deliverables2;
      values["deliverables3"] = this.ruleForm.deliverables3;
      values["quantity1"] = this.ruleForm.quantity1;
      values["quantity2"] = this.ruleForm.quantity2;
      values["quantity3"] = this.ruleForm.quantity3;
      values["memo1"] = this.ruleForm.memo1;
      values["memo2"] = this.ruleForm.memo2;
      values["memo3"] = this.ruleForm.memo3;
      values["remarks"] = this.ruleForm.remarks;
      values["created_by"] = this.$store.getters.full_name;
      postDeliveryData(values)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    putDeliveryData() {
      let values = {};
      values["delivery_code"] = this.ruleForm.delivery_code;
      values["estimate_code"] = this.ruleForm.estimate_code;
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["deliverables1"] = this.ruleForm.deliverables1;
      values["deliverables2"] = this.ruleForm.deliverables2;
      values["deliverables3"] = this.ruleForm.deliverables3;
      values["quantity1"] = this.ruleForm.quantity1;
      values["quantity2"] = this.ruleForm.quantity2;
      values["quantity3"] = this.ruleForm.quantity3;
      values["memo1"] = this.ruleForm.memo1;
      values["memo2"] = this.ruleForm.memo2;
      values["memo3"] = this.ruleForm.memo3;
      values["remarks"] = this.ruleForm.remarks;
      values["modified_by"] = this.$store.getters.full_name;
      putDeliveryData(values)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.cDeliveryForm.method === "put") {
            this.putDeliveryData();
          } else {
            this.postDeliveryData();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancelForm() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
</style>
