import {request} from 'network/request'

export function postProjectData(parameter) {
  return request({
    url: '/project',
    method: 'post',
    data: parameter
  })
}

export function putProjectData(parameter) {
  return request({
    url: '/project',
    method: 'put',
    data: parameter
  })
}

export function getProjectDataAll() {
  return request({
    url: '/project',
    method: 'get'
  })
}

export function getProjectDataOne(parameter) {
  return request({
    url: '/project/one/' + parameter.project_code,
    method: 'get',
    data: parameter
  })
}

export function searchProjectData(parameter) {
  return request({
    url: '/SearchProjectData',
    method: 'post',
    data: parameter
  })
}

export function getTimeLineData(parameter) {
  return request({
    url: '/project/timeline/' + parameter.project_code,
    method: 'get',
    data: parameter
  })
}
