<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    class="demo-ruleForm"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item>
          <el-input v-model="ruleForm.project_name" :disabled="true">
            <template slot="prepend">案件名</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <el-input v-model="ruleForm.customer_name" :disabled="true">
            <template slot="prepend">得意先名</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="14">
        <el-form-item prop="estimate_name">
          <el-autocomplete
            class="inline-input"
            v-model="estimate_name"
            :fetch-suggestions="querySearchEstimate"
            placeholder="コピー元（見積名）を選択"
            style="width: 100%"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <div class="name">
                <!-- {{ (item.value = item.estimate_code) }} -->
                {{ (item.value = item.estimate_name) }}
              </div>
              <span class="category" v-if="item.estimate_pdf_num">
                見積書No.{{ item.estimate_pdf_num }} 得意先:{{
                  item.customer_name
                }}</span
              >
              <span class="category" v-else>
                得意先:{{ item.customer_name }}</span
              >
            </template>
            <template slot="prepend">コピー元（見積名）</template>
          </el-autocomplete>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-button
          type="info"
          size="default"
          @click="copyEstimate"
          style="width: 100%"
          plain
        >
          コピー
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="16">
        <el-form-item prop="estimate_name">
          <el-input v-model="ruleForm.estimate_name">
            <template slot="prepend">案件名(見積)</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <!--设置value-format可以防止axios发送post请求时修改时区-->
    <el-row :gutter="20">
      <el-col :span="19">
        <!--        value-format="yyyy-MM-dd"-->
        <el-form-item prop="estimate_date">
          <el-date-picker
            v-model="ruleForm.estimate_date"
            type="daterange"
            :default-time="['00:00:00', '00:00:00']"
            range-separator="〜"
            style="width: 100%"
            start-placeholder="見積日"
            end-placeholder="有効日"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="0">
      <el-col :span="10">
        <el-form-item class="product" prop="deliverables1">
          <el-input
            v-model="ruleForm.deliverables1"
            placeholder="成果物名"
            maxlength="30"
          >
            <template slot="prepend">成果物①</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item class="product" prop="media1">
          <el-input v-model="ruleForm.media1" placeholder="媒体" maxlength="11">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item class="product" prop="quantity1">
          <el-input v-model="ruleForm.quantity1" placeholder="部数"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item class="product" prop="delivery_date1">
          <el-input
            v-model="ruleForm.delivery_date1"
            placeholder="納品予定日"
            maxlength="13"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="10">
        <el-form-item class="product" prop="deliverables2">
          <el-input
            v-model="ruleForm.deliverables2"
            placeholder="成果物名"
            maxlength="30"
          >
            <template slot="prepend">成果物②</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item class="product" prop="media2">
          <el-input v-model="ruleForm.media2" placeholder="媒体" maxlength="11">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item class="product" prop="quantity2">
          <el-input v-model="ruleForm.quantity2" placeholder="部数"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item class="product" prop="delivery_date2">
          <el-input
            v-model="ruleForm.delivery_date2"
            placeholder="納品予定日"
            maxlength="13"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="10">
        <el-form-item prop="deliverables3">
          <el-input
            v-model="ruleForm.deliverables3"
            placeholder="成果物名"
            maxlength="30"
          >
            <template slot="prepend">成果物③</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="media3">
          <el-input v-model="ruleForm.media3" placeholder="媒体" maxlength="11">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="quantity3">
          <el-input v-model="ruleForm.quantity3" placeholder="部数"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="delivery_date3">
          <el-input
            v-model="ruleForm.delivery_date3"
            placeholder="納品予定日"
            maxlength="13"
          >
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="0">
      <el-col :span="8">
        <el-form-item prop="work1">
          <el-autocomplete
            class="inline-input"
            v-model="ruleForm.work1"
            :fetch-suggestions="querySearchWork"
            placeholder="作業内容①"
            style="width: 100%"
            @select="handleSelect"
          >
            <template slot="prepend">作業内容</template>
          </el-autocomplete>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item prop="work2">
          <el-autocomplete
            class="inline-input"
            v-model="ruleForm.work2"
            :fetch-suggestions="querySearchWork"
            placeholder="作業内容②"
            style="width: 100%"
            @select="handleSelect"
          >
          </el-autocomplete>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item prop="work3">
          <el-autocomplete
            class="inline-input"
            v-model="ruleForm.work3"
            :fetch-suggestions="querySearchWork"
            placeholder="作業内容③"
            style="width: 100%"
            @select="handleSelect"
          >
          </el-autocomplete>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="work_space">
          <el-input
            v-model="ruleForm.work_space"
            placeholder="作業場所"
            maxlength="55"
          >
            <template slot="prepend">作業場所</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="20" justify="end">
      <el-col :span="24">
        <el-form-item prop="payment_conditions">
          <div>支払条件:</div>
          <el-autocomplete
            class="inline-input"
            v-model="ruleForm.payment_conditions"
            :fetch-suggestions="querySearchPaymentConditions"
            placeholder="支払条件"
            style="width: 670px"
            type="textarea"
            rows="3"
            @select="handleSelect"
          >
            <template slot="prepend">支払条件</template>
          </el-autocomplete>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item prop="other">
          <div>その他費用:</div>
          <el-autocomplete
            class="inline-input"
            v-model="ruleForm.other"
            :fetch-suggestions="querySearchOther"
            placeholder="その他費用"
            style="width: 620px"
            type="textarea"
            rows="3"
            @select="handleSelect"
          >
            <template slot="prepend">その他費用</template>
          </el-autocomplete>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="supplement">
          <div>補足事項:</div>
          <el-autocomplete
            class="inline-input"
            v-model="ruleForm.supplement"
            :fetch-suggestions="querySearchSupplement"
            type="textarea"
            style="width: 620px"
            autosize
            placeholder="補足事項"
          >
            <template slot="prepend">補足事項</template>
          </el-autocomplete>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="0">
      <el-col :span="8">
        <el-input v-model="total" :disabled="true">
          <template slot="prepend">税込合計金額</template>
          <!--            <template slot="append">円</template>-->
        </el-input>
      </el-col>
      <el-col :span="8">
        <el-input v-model="tax" :disabled="true">
          <template slot="prepend">消 費 税</template>
          <!--            <template slot="append">円</template>-->
        </el-input>
      </el-col>
      <el-col :span="8">
        <el-input v-model="sub_total" :disabled="true">
          <template slot="prepend">見積合計金額</template>
          <!--            <template slot="append">円</template>-->
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item name="1" class="collapse__initial">
            <template slot="title">お見積金額（イニシャル費用）</template>
            <EstimateDetailsTable
              :c-estimate-details-table-data="initialDetailsTableData"
              :c-estimate-code="ruleForm.estimate_code"
              :c-products-data-all="ProductsDataAll"
              :c-main-flag="false"
              @estimatedetdatareload="estimatedetdatareload"
            >
              <template slot="empty">イニシャルデータがありません</template>
            </EstimateDetailsTable>
          </el-collapse-item>
          <el-collapse-item name="2" class="collapse__running">
            <template slot="title">お見積金額（ランニング費用）</template>
            <EstimateDetailsTable
              :c-estimate-details-table-data="runningDetailsTableData"
              :c-estimate-code="ruleForm.estimate_code"
              :c-products-data-all="ProductsDataAll"
              :c-main-flag="true"
              @estimatedetdatareload="estimatedetdatareload"
            >
              <template slot="empty">ランニングデータがありません</template>
            </EstimateDetailsTable>
          </el-collapse-item>
        </el-collapse>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item>
          <el-button
            type="warning"
            @click="cancelForm()"
            icon="el-icon-document-delete"
            >閉じる
          </el-button>
          <el-dropdown
            split-button
            type="primary"
            @click="submitForm('ruleForm')"
            @command="handleCommand"
            icon="el-icon-document-checked"
            plain
            >登録・更新
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">見積</el-dropdown-item>
              <el-dropdown-item command="1">発注</el-dropdown-item>
              <el-dropdown-item command="2">納品</el-dropdown-item>
              <el-dropdown-item command="3">検収</el-dropdown-item>
              <el-dropdown-item command="4">請求</el-dropdown-item>
              <el-dropdown-item command="5">失注</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import {
  getEstimateDataOne,
  getEstimateDatas,
  getEstimateDetailsDataAll,
  postEstimateData,
  postEstimateDetailsData,
  deleteEstimateDetail,
  putEstimateData,
  putEstimateDetailsData
} from "api/estimates";
import { NewProjectcode } from "@/utils/code_util";
import { getProductDataAll } from "api/products";
import { getUser } from "api/user";
import EstimateDetailsTable from "components/content/project/EstimateDetailsTable";
import { Message } from "element-ui";
import Sortable from "sortablejs";

export default {
  name: "EstimateForm",
  components: {
    // EstimateDetailsForm,
    EstimateDetailsTable
  },
  props: {
    cEstimateForm: {
      type: Object, //指定传入的类型,type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: function() {
        //这样可以指定默认的值
        return {
          project_code: "",
          project_name: "",
          customer_name: "",
          CustomerCode: "",
          estimate_code: "",
          estimate_name: "",
          method: ""
        };
      }
    }
  },
  data() {
    return {
      activeNames: ["1", "2"],
      estimate_code: "",
      estimate_name: "",
      estimateData: [],
      ruleForm: {
        autocomplete_work: [],
        autocomplete_paymentconditions: [],
        autocomplete_other: [],
        autocomplete_supplement: [],
        estimate_code: this.cEstimateForm.estimate_code,
        estimate_name: this.cEstimateForm.estimate_name,
        project_code: this.cEstimateForm.project_code,
        project_name: this.cEstimateForm.project_name,
        customer_name: this.cEstimateForm.customer_name,
        estimate_date: [],
        work1: "",
        work2: "",
        work3: "",
        deliverables1: "",
        deliverables2: "",
        deliverables3: "",
        media1: "",
        media2: "",
        media3: "",
        quantity1: "",
        quantity2: "",
        quantity3: "",
        delivery_date1: "",
        delivery_date2: "",
        delivery_date3: "",
        work_space: "",
        SubTotal: "",
        tax: "",
        Total: "",
        supplement: "",
        remarks: "",
        payment_conditions: "",
        other: "",
        status: ""
      },
      ProductsDataAll: [],
      initialDetailsTableData: [],
      runningDetailsTableData: [],
      userOther: "",
      userPaymentConditions: "",
      userSupplement: "",
      rules: {
        estimate_name: [
          {
            required: true,
            message: "見積名を入力してください",
            trigger: "change"
          }
        ],
        work1: [
          {
            required: true,
            message: "作業内容を入力してください",
            trigger: "change"
          }
        ],
        estimate_date: [
          {
            required: true,
            message: "見積日と見積有効日を入力してください",
            trigger: "change"
          }
        ],
        deliverables1: [
          {
            required: true,
            message: "成果物を入力してください",
            trigger: "blur"
          },
          {
            min: 1,
            max: 30,
            message: "1～30文字まで入力してください",
            trigger: "blur"
          }
        ],
        deliverables2: [
          {
            max: 30,
            message: "30文字まで入力してください",
            trigger: "blur"
          }
        ],
        deliverables3: [
          {
            max: 30,
            message: "1～30文字まで入力してください",
            trigger: "blur"
          }
        ],
        media1: [
          {
            required: true,
            message: "納品媒体を入力してください",
            trigger: "blur"
          },
          {
            max: 11,
            message: "1～11文字まで入力してください",
            trigger: "blur"
          }
        ],
        media2: [
          {
            max: 11,
            message: "11文字まで入力してください",
            trigger: "blur"
          }
        ],
        media3: [
          {
            max: 11,
            message: "11文字まで入力してください",
            trigger: "blur"
          }
        ],
        quantity1: [
          {
            required: true,
            message: "部数を入力してください",
            trigger: "blur"
          }
        ],
        delivery_date1: [
          {
            required: true,
            message: "納品予定日を入力してください",
            trigger: "blur"
          },
          {
            max: 13,
            message: "1~13文字まで入力してください",
            trigger: "blur"
          }
        ],
        delivery_date2: [
          {
            max: 13,
            message: "13文字まで入力してください",
            trigger: "blur"
          }
        ],
        delivery_date3: [
          {
            max: 13,
            message: "13文字まで入力してください",
            trigger: "blur"
          }
        ],
        work_space: [
          {
            required: true,
            message: "作業場所を入力してください",
            trigger: "blur"
          },
          {
            max: 55,
            message: "1~55文字まで入力してください",
            trigger: "blur"
          }
        ],
        payment_conditions: [
          {
            required: true,
            message: "支払条件を入力してください",
            trigger: "change"
          }
        ]
      }
    };
  },
  computed: {
    sub_total() {
      let sumsubtotal = 0;
      for (let initialData of this.initialDetailsTableData) {
        sumsubtotal += parseFloat(initialData.sub_total);
      }
      for (let runningData of this.runningDetailsTableData) {
        sumsubtotal += parseFloat(runningData.sub_total);
      }
      return sumsubtotal.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    },
    tax() {
      let sumtax = 0;
      for (let initialData of this.initialDetailsTableData) {
        sumtax += parseFloat(initialData.tax);
      }
      for (let runningData of this.runningDetailsTableData) {
        sumtax += parseFloat(runningData.tax);
      }
      // sumtax = parseFloat(sumtax) * 0.1;
      return sumtax.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    },
    total() {
      let sumtotal = 0;
      for (let initialData of this.initialDetailsTableData) {
        sumtotal +=
          parseFloat(initialData.sub_total) + parseFloat(initialData.tax);
      }
      for (let runningData of this.runningDetailsTableData) {
        sumtotal +=
          parseFloat(runningData.sub_total) + parseFloat(runningData.tax);
      }
      // sumtotal += sumtotal * 0.1;
      // sumtotal = Number(this.sub_total) + Number(this.tax);
      return sumtotal.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    },
    initial_total() {
      let sum_initial_total = 0;
      for (let initialData of this.initialDetailsTableData) {
        sum_initial_total += parseFloat(initialData.sub_total);
      }
      return sum_initial_total.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    },
    running_total() {
      let sum_running_total = 0;
      for (let runningData of this.runningDetailsTableData) {
        sum_running_total += parseFloat(runningData.sub_total);
      }
      return sum_running_total.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
  },
  created: function() {
    this.getEstimateDetailsDataAll();
    this.getEstimateDatas();
    this.getUser();
    if (this.cEstimateForm.method === "put") {
      this.getEstimateDataOne();
    }
    this.getProductDataAll();
  },
  methods: {
    postEstimateData() {
      let values = {};
      values["estimate_code"] = this.ruleForm.estimate_code;
      values["estimate_name"] = this.ruleForm.estimate_name;
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["estimate_start_date"] = this.ruleForm.estimate_date[0];
      values["estimate_end_date"] = this.ruleForm.estimate_date[1];
      values["work1"] = this.ruleForm.work1;
      values["work2"] = this.ruleForm.work2;
      values["work3"] = this.ruleForm.work3;
      values["deliverables1"] = this.ruleForm.deliverables1;
      values["deliverables2"] = this.ruleForm.deliverables2;
      values["deliverables3"] = this.ruleForm.deliverables3;
      values["media1"] = this.ruleForm.media1;
      values["media2"] = this.ruleForm.media2;
      values["media3"] = this.ruleForm.media3;
      values["quantity1"] = this.ruleForm.quantity1;
      values["quantity2"] = this.ruleForm.quantity2;
      values["quantity3"] = this.ruleForm.quantity3;
      values["delivery_date1"] = this.ruleForm.delivery_date1;
      values["delivery_date2"] = this.ruleForm.delivery_date2;
      values["delivery_date3"] = this.ruleForm.delivery_date3;
      values["work_space"] = this.ruleForm.work_space;
      values["sub_total"] = this.sub_total;
      values["tax"] = this.tax;
      values["total"] = this.total;
      values["initial_total"] = this.initial_total;
      values["running_total"] = this.running_total;
      values["supplement"] = this.ruleForm.supplement;
      values["remarks"] = this.ruleForm.remarks;
      values["payment_conditions"] = this.ruleForm.payment_conditions;
      values["other"] = this.ruleForm.other;
      values["status"] = this.ruleForm.status;
      values["created_by"] = this.$store.getters.full_name;
      postEstimateData(values)
        .then(res => {
          if (res.data.status === 1) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    putEstimateData() {
      let values = {};
      values["estimate_code"] = this.ruleForm.estimate_code;
      values["estimate_name"] = this.ruleForm.estimate_name;
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["estimate_start_date"] = this.ruleForm.estimate_date[0];
      values["estimate_end_date"] = this.ruleForm.estimate_date[1];
      values["work1"] = this.ruleForm.work1;
      values["work2"] = this.ruleForm.work2;
      values["work3"] = this.ruleForm.work3;
      values["deliverables1"] = this.ruleForm.deliverables1;
      values["deliverables2"] = this.ruleForm.deliverables2;
      values["deliverables3"] = this.ruleForm.deliverables3;
      values["media1"] = this.ruleForm.media1;
      values["media2"] = this.ruleForm.media2;
      values["media3"] = this.ruleForm.media3;
      values["quantity1"] = this.ruleForm.quantity1;
      values["quantity2"] = this.ruleForm.quantity2;
      values["quantity3"] = this.ruleForm.quantity3;
      values["delivery_date1"] = this.ruleForm.delivery_date1;
      values["delivery_date2"] = this.ruleForm.delivery_date2;
      values["delivery_date3"] = this.ruleForm.delivery_date3;
      values["work_space"] = this.ruleForm.work_space;
      values["sub_total"] = this.sub_total;
      values["tax"] = this.tax;
      values["total"] = this.total;
      values["initial_total"] = this.initial_total;
      values["running_total"] = this.running_total;
      values["supplement"] = this.ruleForm.supplement;
      values["remarks"] = this.ruleForm.remarks;
      values["payment_conditions"] = this.ruleForm.payment_conditions;
      values["other"] = this.ruleForm.other;
      values["status"] = this.ruleForm.status;
      values["modified_by"] = this.$store.getters.full_name;
      putEstimateData(values)
        .then(res => {
          if (res.data.status === 1) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getEstimateDataOne() {
      let values = {};
      values["estimate_code"] = this.cEstimateForm.estimate_code;
      getEstimateDataOne(values)
        .then(res => {
          this.ruleForm.estimate_date.push(
            res.data.data[0].estimate_start_date
          );
          this.ruleForm.estimate_date.push(res.data.data[0].estimate_end_date);
          this.ruleForm.estimate_name = res.data.data[0].estimate_name;
          this.ruleForm.work1 = res.data.data[0].work1;
          this.ruleForm.work2 = res.data.data[0].work2;
          this.ruleForm.work3 = res.data.data[0].work3;
          this.ruleForm.deliverables1 = res.data.data[0].deliverables1;
          this.ruleForm.deliverables2 = res.data.data[0].deliverables2;
          this.ruleForm.deliverables3 = res.data.data[0].deliverables3;
          this.ruleForm.media1 = res.data.data[0].media1;
          this.ruleForm.media2 = res.data.data[0].media2;
          this.ruleForm.media3 = res.data.data[0].media3;
          this.ruleForm.quantity1 = res.data.data[0].quantity1;
          this.ruleForm.quantity2 = res.data.data[0].quantity2;
          this.ruleForm.quantity3 = res.data.data[0].quantity3;
          this.ruleForm.delivery_date1 = res.data.data[0].delivery_date1;
          this.ruleForm.delivery_date2 = res.data.data[0].delivery_date2;
          this.ruleForm.delivery_date3 = res.data.data[0].delivery_date3;
          this.ruleForm.work_space = res.data.data[0].work_space;
          this.ruleForm.supplement = res.data.data[0].supplement;
          this.ruleForm.remarks = res.data.data[0].remarks;
          this.ruleForm.payment_conditions =
            res.data.data[0].payment_conditions;
          this.ruleForm.other = res.data.data[0].other;
          this.ruleForm.status = res.data.data[0].status;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getProductDataAll() {
      getProductDataAll()
        .then(res => {
          this.ProductsDataAll = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getEstimateDetailsDataAll() {
      let values = {};
      values["estimate_code"] = this.cEstimateForm.estimate_code;
      getEstimateDetailsDataAll(values)
        .then(res => {
          let data = res.data.data;
          if (data) {
            data.forEach(row => {
              // 是否标记
              row["isEdit"] = false;
              // 是否新增
              row["isAdd"] = false;
            });
            // 调用函数对见积详细列表数据按是否是保守进行分离
            this.separateDetailsTableData(data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getUser() {
      let valuse = {};
      valuse["user_name"] = localStorage.getItem("user_name");
      getUser(valuse)
        .then(res => {
          this.autocomplete_supplement = this.loadSupplement(
            res.data.data[0].supplement
          );
          this.autocomplete_paymentconditions = this.loadPaymentConditions(
            res.data.data[0].payment_conditions
          );
          this.autocomplete_other = this.loadOther(res.data.data[0].other);
        })
        .catch(err => {
          console.log("2022-06-08 16:59:05 ✈️✐☡✐☡✐☡✐☡✐☡✐☡✐☡✐☡:", err);
        });
    },
    estimatedetdatareload() {
      // 见积详细列表数据数量有变化时重新获取数据
      this.getEstimateDetailsDataAll();
    },
    separateDetailsTableData(param) {
      // 将数据以是否是保守案件(MainFlag)分离
      this.initialDetailsTableData = [];
      this.runningDetailsTableData = [];
      for (let data of param) {
        if (!data.main_flag) {
          this.initialDetailsTableData.push(data);
        } else {
          this.runningDetailsTableData.push(data);
        }
      }
    },
    putEstimateDetailsData(estimateDetailsData) {
      let values = {};
      values["index"] = estimateDetailsData.index;
      values["estimate_code"] = estimateDetailsData.estimate_code;
      values["estimate_details_code"] =
        estimateDetailsData.estimate_details_code;
      values["product_code"] = estimateDetailsData.product_code;
      values["product_name"] = estimateDetailsData.product_name;
      values["quantity"] = estimateDetailsData.quantity;
      values["price"] = estimateDetailsData.price;
      values["sub_total"] = estimateDetailsData.sub_total;
      values["tax"] = estimateDetailsData.tax;
      values["total"] = estimateDetailsData.total;
      values["remarks"] = estimateDetailsData.remarks;
      values["main_flag"] = estimateDetailsData.main_flag;
      values["modified_by"] = estimateDetailsData.full_name;
      putEstimateDetailsData(values)
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          switch (this.cEstimateForm.method) {
            case "post":
              this.postEstimateData();
              break;
            case "put":
              this.putEstimateData();
              break;
            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      //点击提交时更新见积详细列表的index
      this.initialDetailsTableData.forEach(value => {
        this.putEstimateDetailsData(value);
      });
      this.runningDetailsTableData.forEach(value =>
        this.putEstimateDetailsData(value)
      );
    },
    handleCommand(command) {
      this.ruleForm.status = command;
      this.submitForm("ruleForm");
    },
    getEstimateDatas() {
      let values = {};
      values["user_name"] = localStorage.getItem("user_name");
      getEstimateDatas(values)
        .then(res => {
          this.estimateData = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    copyEstimate() {
      let values = {};
      values["estimate_code"] = this.estimate_code;
      getEstimateDataOne(values)
        .then(res => {
          this.ruleForm.estimate_date.push(
            res.data.data[0].estimate_start_date
          );
          this.ruleForm.estimate_date.push(res.data.data[0].estimate_end_date);
          this.ruleForm.estimate_name = res.data.data[0].estimate_name;
          this.ruleForm.work1 = res.data.data[0].work1;
          this.ruleForm.work2 = res.data.data[0].work2;
          this.ruleForm.work3 = res.data.data[0].work3;
          this.ruleForm.deliverables1 = res.data.data[0].deliverables1;
          this.ruleForm.deliverables2 = res.data.data[0].deliverables2;
          this.ruleForm.deliverables3 = res.data.data[0].deliverables3;
          this.ruleForm.media1 = res.data.data[0].media1;
          this.ruleForm.media2 = res.data.data[0].media2;
          this.ruleForm.media3 = res.data.data[0].media3;
          this.ruleForm.quantity1 = res.data.data[0].quantity1;
          this.ruleForm.quantity2 = res.data.data[0].quantity2;
          this.ruleForm.quantity3 = res.data.data[0].quantity3;
          this.ruleForm.delivery_date1 = res.data.data[0].delivery_date1;
          this.ruleForm.delivery_date2 = res.data.data[0].delivery_date2;
          this.ruleForm.delivery_date3 = res.data.data[0].delivery_date3;
          this.ruleForm.work_space = res.data.data[0].work_space;
          this.ruleForm.supplement = res.data.data[0].supplement;
          this.ruleForm.remarks = res.data.data[0].remarks;
          this.ruleForm.payment_conditions =
            res.data.data[0].payment_conditions;
          this.ruleForm.other = res.data.data[0].other;
        })
        .catch(err => {
          console.log(err);
        });
      getEstimateDetailsDataAll(values)
        .then(res => {
          let data = res.data.data;
          if (data) {
            data.forEach(row => {
              row.estimate_details_code = NewProjectcode();
              row.estimate_code = this.ruleForm.estimate_code;
              postEstimateDetailsData(row)
                .then(res => {
                  if (res.data.status === 1) {
                    this.estimatedetdatareload();
                  } else {
                    Message({
                      showClose: true,
                      message: res.data.message,
                      type: "error"
                    });
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            });
          }
        })
        .catch(err => {
          console.log(err);
        });

      values["estimate_code"] = this.ruleForm.estimate_code;
      getEstimateDetailsDataAll(values)
        .then(res => {
          let data = res.data.data;
          console.log("====");
          console.log(data);
          if (data) {
            data.forEach(row => {
              let values = {};
              values["estimate_details_code"] = row.estimate_details_code;
              deleteEstimateDetail(values)
                .then(res => {
                  if (res.data.status === 1) {
                    this.estimatedetdatareload();
                  } else {
                    Message({
                      showClose: true,
                      message: res.data.message,
                      type: "error"
                    });
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    cancelForm() {
      this.$emit("cancel");
    },
    dialogTableEstimate() {
      this.detailsTableVisible = true;
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields()
    // },
    //见积复制框自动输入检索
    querySearchEstimate(queryString, cb) {
      var estimateData = this.estimateData;
      var results = queryString
        ? estimateData.filter(this.createFilter(queryString))
        : estimateData;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 见积画面作业内容输入框自动检索
    querySearchWork(queryString, cb) {
      var autocomplete_work = this.autocomplete_work;
      var results = queryString
        ? autocomplete_work.filter(this.createFilter(queryString))
        : autocomplete_work;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearchPaymentConditions(queryString, cb) {
      var autocomplete_paymentconditions = this.autocomplete_paymentconditions;
      var results = queryString
        ? autocomplete_paymentconditions.filter(this.createFilter(queryString))
        : autocomplete_paymentconditions;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearchOther(queryString, cb) {
      var autocomplete_other = this.autocomplete_other;
      var results = queryString
        ? autocomplete_other.filter(this.createFilter(queryString))
        : autocomplete_other;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearchSupplement(queryString, cb) {
      var autocomplete_supplement = this.autocomplete_supplement;
      var results = queryString
        ? autocomplete_supplement.filter(this.createFilter(queryString))
        : autocomplete_supplement;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1
        );
      };
    },
    loadWork() {
      return [
        { value: "仕様検討" },
        { value: "設計" },
        { value: "システム開発" },
        { value: "テスト" },
        { value: "HW購入" },
        { value: "セットアップ" },
        { value: "サーバー構築" },
        { value: "導入及び保守" },
        { value: "検証ツールご提供" },
        { value: "インストーラーご用意" },
        { value: "検証ツールご提供・インストーラーご用意" },
        { value: "仕様検討・設計" },
        { value: "システム開発・テスト" },
        { value: "HW購入/セットアップ" },
        { value: "サーバー構築・導入及び保守" },
        { value: "プログラム修正・テスト" },
        { value: "本番環境デプロイ" }
      ];
    },
    loadPaymentConditions(userPaymentConditions) {
      return [
        {
          value: userPaymentConditions
        },
        {
          value:
            "各機能毎に納品、検収後、弊社請求書を元に月末締め、翌末までに銀行振込とさせて頂きます。"
        },
        {
          value:
            "弊社請求書を元に月末締め、翌末までに銀行振り込みとさせて頂きます。"
        },
        {
          value:
            "弊社請求書を元に月末締め、翌末までに銀行振り込みとさせて頂きます。\n※ハートウェア購入の場合は、発注時にご請求させて頂きます。"
        }
      ];
    },
    loadOther(userOther) {
      return [
        {
          value: userOther
        },
        {
          value:
            "追加作業が発生した場合は、別途精算させて頂きます。出張の場合の交通費、宿泊費は実費\n精算させて頂きます。"
        }
      ];
    },
    loadSupplement(supplement) {
      return [
        {
          value: supplement
        },
        {
          value: "在庫状況により納期が変動することが御座います。"
        }
      ];
    },
    handleSelect(item) {
      // 将选择的见积CD回传给data
      this.estimate_code = item.estimate_code;
    },
    handleChange(value) {
      console.log(value);
    },
    initialDrop() {
      const tbody = document.querySelector(".collapse__initial tbody");
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.initialDetailsTableData.splice(oldIndex, 1)[0];
          _this.initialDetailsTableData.splice(newIndex, 0, currRow);
          _this.initialDetailsTableData.forEach((value, index) => {
            return (_this.initialDetailsTableData[index].index = index);
          });
        }
      });
    },
    runningDrop() {
      const tbody = document.querySelector(".collapse__running tbody");
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.runningDetailsTableData.splice(oldIndex, 1)[0];
          _this.runningDetailsTableData.splice(newIndex, 0, currRow);
          _this.runningDetailsTableData.forEach((value, index) => {
            return (_this.runningDetailsTableData[index].index = index);
          });
        }
      });
    }
  },
  mounted() {
    this.initialDrop();
    this.runningDrop();
  }
};
</script>
<style scoped>
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.category {
  font-size: 12px;
  color: #b4b4b4;
}

.highlighted .category {
  color: #ddd;
}
/* 去掉成果物部分的外间距 */
.product {
  margin: 0;
}
</style>
