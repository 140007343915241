<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="見積書" name="first">
      <el-table :data="cTableDataEstimate" style="width: 100%">
        <!--        修改element自带空表格是暂无数据的提示-->
        <template slot="empty">
          <p>見積データがありません</p>
        </template>
        <el-table-column label="見積書No." width="150%">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.estimate_pdf_num
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="見積名" width="400%">
          <template slot-scope="scope">
            <a @click="handleEditEstimate(scope.$index, scope.row)" class="rowTxt">{{ scope.row.estimate_name }}</a>
          </template>
        </el-table-column>
        <el-table-column label="案件名" width="400%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.project_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="得意先" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.customer_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="税込金額" align="right" width="250%">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top-start">
              <p>
                税抜金額:
                <span style="float: right">{{ scope.row.sub_total }}</span>
              </p>
              <p>
                税金: <span style="float: right">{{ scope.row.tax }}</span>
              </p>
              <p>
                税込金額:
                <span style="float: right">{{ scope.row.total }}</span>
              </p>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium" effect="plain">{{ scope.row.total }}
                </el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="作成年月" width="200%" show-overflow-tooltip>
          <!-- 需要先判断created_at是否存在再传插槽进行渲染,不然会在console.log报错. -->
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.created_at && scope.row.created_at.slice(0, 7)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="進捗" width="200%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">見積⇨<span style="color :#0A3D64">発注</span></span>
            <span v-else-if="scope.row.status == 2">見積⇨<span style="color :#0A3D64">発注⇨<span
                      style="color :#EAAD5A">納品</span></span></span>
            <span v-else-if="scope.row.status == 3">見積⇨<span style="color :#0A3D64">発注⇨<span
                      style="color :#EAAD5A">納品⇨<span style="color :#A8B293">検収</span></span></span></span>
            <span v-else-if="scope.row.status == 4">見積⇨<span style="color :#0A3D64">発注⇨<span
                      style="color :#EAAD5A">納品⇨<span style="color :#A8B293">検収⇨<span
                          style="color :#78922C">請求</span></span></span></span></span>
            <span v-else-if="scope.row.status == 5">見積⇨<span style="color :#DF8053">失注</span></span>
            <span v-else>見積</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-dropdown @command="handleCommand" trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-menu"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommand('toOrder', scope.$index, scope.row)
                  ">注文書へ進む
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'estimatePdfPreview',
                  scope.$index,
                  scope.row
                )
                  ">見積書プレビュー
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'estimatePdfDownload',
                  scope.$index,
                  scope.row
                )
                  ">見積書ダウンロード
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="注文書" name="second">
      <el-table :data="cTableDataOrder" style="width: 100%">
        <template slot="empty">
          <p>受注データがありません</p>
        </template>
        <el-table-column label="注文請書No." width="150%">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.invoice_order_pdf_num
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="注文書No." width="150%">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.order_pdf_num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="業務案件名" width="400%">
          <template slot-scope="scope">
            <a @click="handleEditOrder(scope.$index, scope.row)" class="rowTxt">{{ scope.row.estimate_name }}</a>
          </template>
        </el-table-column>
        <el-table-column label="得意先" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.customer_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="作業内容" width="350%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.work }}</span>
          </template>
        </el-table-column>
        <el-table-column label="納入物" width="200%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.deliverables }}</span>
          </template>
        </el-table-column>
        <el-table-column label="委託料" align="right" width="200%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.commission }}</span>
          </template>
        </el-table-column>
        <el-table-column label="その他" width="350%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.other }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-dropdown @command="handleCommand" trigger="click" placement="bottom" visible-change="visibleChange">
              <span class="el-dropdown-link">
                <i class="el-icon-menu"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommand('toDelivery', scope.$index, scope.row)
                  ">納品書へ進む
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'invoiceOrderPdfPreview',
                  scope.$index,
                  scope.row
                )
                  ">注文請書プレビュー
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'orderPdfPreview',
                  scope.$index,
                  scope.row
                )
                  ">注文書プレビュー
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'invoiceOrderPdfDownload',
                  scope.$index,
                  scope.row
                )
                  ">注文請書ダウンロード
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'orderPdfDownload',
                  scope.$index,
                  scope.row
                )
                  ">注文書ダウンロード
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="納品書" name="third">
      <el-table :data="cTableDataDelivery" style="width: 100%">
        <template slot="empty">
          <p>納品データがありません</p>
        </template>
        <el-table-column label="納品書No." width="150%">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.delivery_pdf_num
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="案件名" width="400%" show-overflow-tooltip>
          <template slot-scope="scope">
            <a @click="handleEditDelivery(scope.$index, scope.row)" class="rowTxt">
              {{ scope.row.project_name }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="得意先" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.customer_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="納入物1" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.deliverables1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="納入物2" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.deliverables2 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="納入物3" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.deliverables3 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="※注" width="400%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.remarks }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-dropdown @command="handleCommand" trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-menu"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommand('toAcceptance', scope.$index, scope.row)
                  ">検収書へ進む
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'deliveryPdfPreview',
                  scope.$index,
                  scope.row
                )
                  ">納品書プレビュー
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'deliveryPdfDownload',
                  scope.$index,
                  scope.row
                )
                  ">納品書ダウンロード
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="検収書" name="fourth">
      <el-table :data="cTableDataAcceptance" style="width: 100%">
        <template slot="empty">
          <p>検収データがありません</p>
        </template>
        <el-table-column label="検収書No." width="150%">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.acceptance_pdf_num
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="案件名" width="400%" show-overflow-tooltip>
          <template slot-scope="scope">
            <a @click="handleEditAcceptance(scope.$index, scope.row)" class="rowTxt">
              {{ scope.row.project_name }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="得意先" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.customer_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="納入物1" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.deliverables1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="納入物2" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.deliverables2 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="納入物3" width="300%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.deliverables3 }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="※注" width="400%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.remarks }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-dropdown @command="handleCommand" trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-menu"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommand('toInvoice', scope.$index, scope.row)
                  ">請求書へ進む
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'acceptancePdfPreview',
                  scope.$index,
                  scope.row
                )
                  ">検収書プレビュー
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'acceptancePdfDownload',
                  scope.$index,
                  scope.row
                )
                  ">
                  検収書ダウンロード
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="請求書" name="five">
      <el-table :data="cTableDataInvoice" style="width: 100%">
        <template slot="empty">
          <p>請求データがありません</p>
        </template>
        <el-table-column label="請求書No." width="150%">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.invoice_pdf_num
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="業務件名" width="400%" show-overflow-tooltip>
          <template slot-scope="scope">
            <a @click="handleEditInvoice(scope.$index, scope.row)" class="rowTxt">{{ scope.row.project_name }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="得意先名" width="300%">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.customer_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="税込金額" align="right" width="250%">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top-start">
              <p>
                税抜金額:
                <span style="float: right">{{ scope.row.sub_total }}</span>
              </p>
              <p>
                税金: <span style="float: right">{{ scope.row.tax }}</span>
              </p>
              <p>
                税込金額:
                <span style="float: right">{{ scope.row.total }}</span>
              </p>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium" effect="plain">{{ scope.row.total }}
                </el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="請求日" width="200%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.invoice_date }}</span>
          </template>
        </el-table-column>
        <el-table-column label="※注" width="400%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.note }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-dropdown @command="handleCommand" trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-menu"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item
                  :command="
                    beforeHandleCommand('invoice', scope.$index, scope.row)
                  "
                  >入金済み
                </el-dropdown-item> -->
                <el-dropdown-item :command="beforeHandleCommand(
                  'invoicePdfPreview',
                  scope.$index,
                  scope.row
                )
                  ">請求書プレビュー
                </el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommand(
                  'invoicePdfDownload',
                  scope.$index,
                  scope.row
                )
                  ">請求書ダウンロード
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { getEstimatePdf } from "@/api/estimates";
import { getInvoiceOrderPdf, getOrderPdf } from "@/api/order";
import { getDeliveryPdf } from "@/api/delivery";
import { getAcceptancePdf } from "@/api/acceptance";
import { getInvoicePdf } from "@/api/invoice";
import { Message } from "element-ui";

export default {
  name: "ProjectTabTable",
  props: {
    cTableDataEstimate: {
      type: Array,
      default() {
        return [];
      }
    },
    cTableDataOrder: {
      type: Array,
      default() {
        return [];
      }
    },
    cTableDataDelivery: {
      type: Array,
      default() {
        return [];
      }
    },
    cTableDataAcceptance: {
      type: Array,
      default() {
        return [];
      }
    },
    cTableDataInvoice: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      activeName: ""
    };
  },
  created: function () {
    let active = this.$route.query.activeName;
    if (active) {
      this.activeName = active;
    } else {
      this.activeName = "first";
    }
  },
  methods: {
    beforeHandleCommand(flag, index, command) {
      return {
        flag: flag,
        index: index,
        command: command
      };
    },
    handleCommand(values) {
      const formItem = values.command;
      const formIndex = values.index;
      switch (values.flag) {
        //见积列表
        case "toOrder":
          this.toOrderForm(formIndex, formItem);
          break;
        case "estimatePdfPreview":
          this.estimatePdfPreview(formIndex, formItem);
          break;
        case "estimatePdfDownload":
          this.estimatePdfDownload(formIndex, formItem);
          break;
        //注文列表
        case "toDelivery":
          this.toDeliveryForm(formIndex, formItem);
          break;
        case "invoiceOrderPdfPreview":
          this.invoiceOrderPdfPreview(formIndex, formItem);
          break;
        case "orderPdfPreview":
          this.orderPdfPreview(formIndex, formItem);
          break;
        case "invoiceOrderPdfDownload":
          this.invoiceOrderPdfDownload(formIndex, formItem);
          break;
        case "orderPdfDownload":
          this.orderPdfDownload(formIndex, formItem);
          break;
        //纳品列表
        case "toAcceptance":
          this.toAcceptanceForm(formIndex, formItem);
          break;
        case "deliveryPdfPreview":
          this.deliveryPdfPreview(formIndex, formItem);
          break;
        case "deliveryPdfDownload":
          this.deliveryPdfDownload(formIndex, formItem);
          break;
        //検収列表
        case "toInvoice":
          this.toInvoiceForm(formIndex, formItem);
          break;
        case "acceptancePdfPreview":
          this.acceptancePdfPreview(formIndex, formItem);
          break;
        case "acceptancePdfDownload":
          this.acceptancePdfDownload(formIndex, formItem);
          break;
        case "invoicePdfPreview":
          this.invoicePdfPreview(formIndex, formItem);
          break;
        case "invoicePdfDownload":
          this.invoicePdfDownload(formIndex, formItem);
          break;
        //
      }
    },
    //发送"evEstimateForm"事件给ProjectForm，显示见积画面
    handleEditEstimate(index, row) {
      this.$emit("evEstimateForm", { estimate_code: row.estimate_code });
    },
    //发送"evOrderForm"事件给ProjectForm，显示注文画面
    toOrderForm(index, row) {
      this.activeName = "second";
      this.$emit("evOrderForm", {
        estimate_code: row.estimate_code,
        estimate_name: row.estimate_name,
        work1: row.work1,
        work2: row.work2,
        work3: row.work3,
        deliverables1: row.deliverables1,
        deliverables2: row.deliverables2,
        deliverables3: row.deliverables3,
        initial_total: row.initial_total,
        running_total: row.running_total,
        estimate_pdf_num: row.estimate_pdf_num
      });
    },
    handleEditOrder(index, row) {
      this.$emit("evOrderForm", {
        order_code: row.order_code,
        estimate_code: row.estimate_code,
        estimate_name: row.estimate_name
      });
    },
    // 发送"evDeliveryForm"事件给ProjectForm，显示纳品画面
    toDeliveryForm(index, row) {
      this.activeName = "third";
      this.$emit("evDeliveryForm", { estimate_code: row.estimate_code });
    },
    handleEditDelivery(index, row) {
      this.$emit("evDeliveryForm", {
        delivery_code: row.delivery_code,
        estimate_code: row.estimate_code
      });
    },
    // 发送"evAcceptanceForm"事件给ProjectForm，显示检收画面
    toAcceptanceForm(index, row) {
      this.activeName = "fourth";
      this.$emit("evAcceptanceForm", {
        delivery_code: row.delivery_code,
        estimate_code: row.estimate_code
      });
    },
    handleEditAcceptance(index, row) {
      this.$emit("evAcceptanceForm", {
        acceptance_code: row.acceptance_code,
        estimate_code: row.estimate_code
      });
    },
    // 发送"evInvoiceForm"事件给ProjectForm，显示请求画面
    toInvoiceForm(index, row) {
      this.activeName = "five";
      this.$emit("evInvoiceForm", {
        delivery_code: row.delivery_code,
        estimate_code: row.estimate_code
      });
    },
    handleEditInvoice(index, row) {
      this.$emit("evInvoiceForm", {
        invoice_code: row.invoice_code,
        estimate_code: row.estimate_code
      });
    },
    //预览见积书
    estimatePdfPreview(index, row) {
      let values = {};
      values["estimate_code"] = row.estimate_code;
      console.log(values);
      getEstimatePdf(values)
        .then(res => {
          if (res.data.status === 1) {
            let filename = res.data.filename;
            this.$emit("evPdfPreview", {
              pdf_path:
                "estimate/" +
                filename.slice(0, 4) +
                "-" +
                filename.slice(4, 6) +
                "-" +
                filename.slice(6, 8) +
                "/" +
                filename
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //下载见积书pdf文件
    estimatePdfDownload(index, row) {
      let values = {};
      values["estimate_code"] = row.estimate_code;
      getEstimatePdf(values)
        .then(res => {
          if (res.data.status === 1) {
            window.open("/v1/estimate/download/" + res.data.filename);
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //预览注文请书
    invoiceOrderPdfPreview(index, row) {
      let values = {};
      values["order_code"] = row.order_code;
      getInvoiceOrderPdf(values)
        .then(res => {
          if (res.data.status === 1) {
            let filename = res.data.filename;
            this.$emit("evPdfPreview", {
              pdf_path:
                "invoiceorder/" +
                filename.slice(0, 4) +
                "-" +
                filename.slice(4, 6) +
                "-" +
                filename.slice(6, 8) +
                "/" +
                filename
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //预览注文书
    orderPdfPreview(index, row) {
      let values = {};
      values["order_code"] = row.order_code;
      getOrderPdf(values)
        .then(res => {
          if (res.data.status === 1) {
            let filename = res.data.filename;
            this.$emit("evPdfPreview", {
              pdf_path:
                "order/" +
                filename.slice(0, 4) +
                "-" +
                filename.slice(4, 6) +
                "-" +
                filename.slice(6, 8) +
                "/" +
                filename
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //下载注文请书
    invoiceOrderPdfDownload(index, row) {
      let values = {};
      values["order_code"] = row.order_code;
      getInvoiceOrderPdf(values)
        .then(res => {
          if (res.data.status === 1) {
            window.open("/v1/order/invoiceorder/download/" + res.data.filename);
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //下载注文书
    orderPdfDownload(index, row) {
      let values = {};
      values["order_code"] = row.order_code;
      getOrderPdf(values)
        .then(res => {
          if (res.data.status === 1) {
            window.open("/v1/order/order/download/" + res.data.filename);
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //预览纳品书
    deliveryPdfPreview(index, row) {
      let values = {};
      values["delivery_code"] = row.delivery_code;
      getDeliveryPdf(values)
        .then(res => {
          if (res.data.status === 1) {
            let filename = res.data.filename;
            this.$emit("evPdfPreview", {
              pdf_path:
                "delivery/" +
                filename.slice(0, 4) +
                "-" +
                filename.slice(4, 6) +
                "-" +
                filename.slice(6, 8) +
                "/" +
                filename
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //下载纳品书
    deliveryPdfDownload(index, row) {
      let values = {};
      values["delivery_code"] = row.delivery_code;
      getDeliveryPdf(values)
        .then(res => {
          if (res.data.status === 1) {
            window.open("/v1/delivery/download/" + res.data.filename);
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //预览检收书
    acceptancePdfPreview(index, row) {
      let values = {};
      values["acceptance_code"] = row.acceptance_code;
      getAcceptancePdf(values)
        .then(res => {
          if (res.data.status === 1) {
            let filename = res.data.filename;
            this.$emit("evPdfPreview", {
              pdf_path:
                "acceptance/" +
                filename.slice(0, 4) +
                "-" +
                filename.slice(4, 6) +
                "-" +
                filename.slice(6, 8) +
                "/" +
                filename
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //下载检收书
    acceptancePdfDownload(index, row) {
      let values = {};
      values["acceptance_code"] = row.acceptance_code;
      getAcceptancePdf(values)
        .then(res => {
          if (res.data.status === 1) {
            window.open("/v1/acceptance/download/" + res.data.filename);
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //预览请求书
    invoicePdfPreview(index, row) {
      let values = {};
      values["invoice_code"] = row.invoice_code;
      console.log("2022-06-23 16:31:01 🚞—-✄———-✄———-✄—-:", values);
      getInvoicePdf(values)
        .then(res => {
          if (res.data.status === 1) {
            let filename = res.data.filename;
            this.$emit("evPdfPreview", {
              pdf_path:
                "invoice/" +
                filename.slice(0, 4) +
                "-" +
                filename.slice(4, 6) +
                "-" +
                filename.slice(6, 8) +
                "/" +
                filename
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //下载请求书
    invoicePdfDownload(index, row) {
      let values = {};
      values["invoice_code"] = row.invoice_code;
      getInvoicePdf(values)
        .then(res => {
          if (res.data.status === 1) {
            window.open("/v1/invoice/download/" + res.data.filename);
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style scoped></style>
