<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    class="demo-ruleForm"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="project_name">
          <el-input v-model="ruleForm.project_name">
            <template slot="prepend">業務件名</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="customer_name">
          <el-input v-model="ruleForm.customer_name" :disabled="true">
            <template slot="prepend">得意先名</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item prop="distinguish">
          <el-radio-group v-model="ruleForm.distinguish" size="small">
            <el-radio-button label="0">1:定期</el-radio-button>
            <el-radio-button label="1">2:年一</el-radio-button>
            <el-radio-button label="2">3:スポット</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="classification">
          <el-radio-group v-model="ruleForm.classification" size="small">
            <el-radio-button label="0">A:開発</el-radio-button>
            <el-radio-button label="1">B:リセール</el-radio-button>
            <el-radio-button label="2">C:保守</el-radio-button>
            <el-radio-button label="3">D:工事</el-radio-button>
            <el-radio-button label="4">E:その他</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item prop="invoice_date">
          <el-date-picker
            v-model="ruleForm.invoice_date"
            type="date"
            placeholder="請求予定日"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="estimate_code">
          <el-select
            v-model="ruleForm.estimate_code"
            @change="reLoadInvoiceDetailsData"
            multiple
            style="width: 100%"
            placeholder="見積から請求詳細を追加"
          >
            <el-option
              v-for="item in EstimateDataAll"
              :key="item.estimate_code"
              :label="item.estimate_name"
              :value="item.estimate_code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item prop="total">
          <el-input v-model="total" :disabled="true">
            <template slot="prepend">税込合計金額</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item prop="tax">
          <el-input v-model="tax" :disabled="true">
            <template slot="prepend">消費税</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item prop="sub_total">
          <el-input v-model="sub_total" :disabled="true">
            <template slot="prepend">合計金額</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <EstimateDetailsTable
          :c-estimate-details-table-data="tableInvoiceData"
          :c-estimate-code="ruleForm.estimate_code"
          :c-invoice-code="ruleForm.invoice_code"
          :c-products-data-all="ProductsDataAll"
          :c-invoice-form="true"
          @inviocedetdatareload="reLoadInvoiceDetailsData"
        >
          <template slot="empty">ランニングデータがありません</template>
        </EstimateDetailsTable>
      </el-col>
    </el-row>
    <br />
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="" prop="note">
          <el-input v-model="ruleForm.note">
            <template slot="prepend">※(注)</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20" justify="start">
      <el-col :span="12">
        <el-form-item prop="bank_name">
          <el-input v-model="ruleForm.bank_name">
            <template slot="prepend">銀行</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="bank_number">
          <el-input v-model="ruleForm.bank_number">
            <template slot="prepend">口座</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="bank_user">
          <el-input v-model="ruleForm.bank_user">
            <template slot="prepend">口座名</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item>
          <el-button-group>
            <el-button
              type="warning"
              @click="cancelForm()"
              icon="el-icon-document-delete"
              plain
              >閉じる</el-button
            >
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              icon="el-icon-document-checked"
              plain
              >登録・更新</el-button
            >
          </el-button-group>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import EstimateDetailsTable from "components/content/project/EstimateDetailsTable";
import { getProductDataAll } from "api/products";
import { getEstimateDetailsDataAll, getEstimateDatas } from "api/estimates";
import {
  getInvoiceDataOne,
  postInvoiceData,
  putInvoiceData,
  getInvoiceDetailsDataAll,
  postInvoiceDetailsData
} from "@/api/invoice";
import { Message } from "element-ui";
import Sortable from "sortablejs";
import { NewProjectcode } from "@/utils/code_util";

export default {
  name: "InvoiceForm",
  components: {
    EstimateDetailsTable
  },
  props: {
    cInvoiceForm: {
      type: Object,
      default: function() {
        return {
          project_name: "",
          project_code: "",
          customer_name: "",
          estimate_code: "",
          delivery_code: "",
          invoice_code: "",
          method: ""
        };
      }
    }
  },
  data() {
    return {
      tableInvoiceData: [],
      ProductsDataAll: [],
      EstimateDataAll: [],
      ruleForm: {
        invoice_code: this.cInvoiceForm.invoice_code,
        project_name: this.cInvoiceForm.project_name,
        project_code: this.cInvoiceForm.project_code,
        delivery_code: this.cInvoiceForm.delivery_code,
        distinguish: "",
        classification: "",
        invoice_date: new Date(),
        // estimate_code: this.cInvoiceForm.estimate_code,
        estimate_code: [],
        customer_name: this.cInvoiceForm.customer_name,
        total: "0",
        tax: "0",
        sub_total: "0",
        bank_name: "PayPay銀行 本店営業部",
        bank_number: "普通 8451691",
        bank_user: "株式会社ブリッジ",
        note: ""
      },
      rules: {
        customer_name: [
          {
            required: true,
            message: "得意先名を入力してください",
            trigger: "change"
          }
        ]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今月20日",
            onClick(picker) {
              const date = new Date();
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              let day = new Date(year, month, 20);
              let endTime = year + "-" + month + "-" + day.getDate();
              // date.setDate(20);
              picker.$emit("pick", endTime);
            }
          },
          {
            text: "今月末",
            onClick(picker) {
              const date = new Date();
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              month = month < 10 ? "0" + month : month;
              let day = new Date(year, month, 0);
              let endTime = year + "-" + month + "-" + day.getDate();
              picker.$emit("pick", endTime);
            }
          }
        ]
      }
    };
  },
  created() {
    if (this.cInvoiceForm.method === "put") {
      this.getInvoiceDataOne();
      this.getInvoiceDetailsDataAll();
    } else {
      this.ruleForm.estimate_code = this.cInvoiceForm.estimate_code.split(",");
      this.reLoadInvoiceDetailsData();
    }
    this.getProductDataAll();
    this.getEstimateDatas();
  },
  computed: {
    sub_total() {
      let sumsubtotal = 0;
      if (this.tableInvoiceData) {
        for (let invoiceData of this.tableInvoiceData) {
          sumsubtotal += parseFloat(invoiceData.sub_total);
        }
        return sumsubtotal.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      } else {
        return "0";
      }
    },
    tax() {
      let sumtax = 0;
      if (this.tableInvoiceData) {
        for (let invoiceData of this.tableInvoiceData) {
          sumtax += parseFloat(invoiceData.tax);
        }
        return sumtax.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      } else {
        return "0";
      }
    },
    total() {
      let sumtotal = 0;
      if (this.tableInvoiceData) {
        for (let invoiceData of this.tableInvoiceData) {
          sumtotal +=
            parseFloat(invoiceData.sub_total) + parseFloat(invoiceData.tax);
        }
        return sumtotal.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      } else {
        return "0";
      }
    }
  },
  methods: {
    postInvoiceData() {
      let values = {};
      values["estimate_code"] = this.ruleForm.estimate_code.toString();
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["delivery_code"] = this.ruleForm.delivery_code;
      values["invoice_code"] = this.ruleForm.invoice_code;
      values["distinguish"] = this.ruleForm.distinguish;
      values["classification"] = this.ruleForm.classification;
      values["invoice_date"] = this.ruleForm.invoice_date;
      values["sub_total"] = this.sub_total;
      values["tax"] = this.tax;
      values["total"] = this.total;
      values["note"] = this.ruleForm.note;
      values["bank_name"] = this.ruleForm.bank_name;
      values["bank_number"] = this.ruleForm.bank_number;
      values["bank_user"] = this.ruleForm.bank_user;
      values["created_by"] = this.$store.getters.full_name;
      postInvoiceData(values)
        .then(res => {
          if (res.data.status === 1) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    putInvoiceData() {
      let values = {};
      values["estimate_code"] = this.ruleForm.estimate_code.join(",");
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["delivery_code"] = this.ruleForm.delivery_code;
      values["invoice_code"] = this.ruleForm.invoice_code;
      values["distinguish"] = this.ruleForm.distinguish;
      values["classification"] = this.ruleForm.classification;
      values["invoice_date"] = this.ruleForm.invoice_date;
      values["sub_total"] = this.sub_total;
      values["tax"] = this.tax;
      values["total"] = this.total;
      values["note"] = this.ruleForm.note;
      values["bank_name"] = this.ruleForm.bank_name;
      values["bank_number"] = this.ruleForm.bank_number;
      values["bank_user"] = this.ruleForm.bank_user;
      values["modified_by"] = this.$store.getters.full_name;
      putInvoiceData(values)
        .then(res => {
          if (res.data.status === 1) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getInvoiceDataOne() {
      let values = {};
      values["invoice_code"] = this.cInvoiceForm.invoice_code;
      getInvoiceDataOne(values)
        .then(res => {
          // this.ruleForm.estimate_code = res.data.data[0].estimate_code
          this.ruleForm.project_name = res.data.data[0].project_name;
          this.ruleForm.project_code = res.data.data[0].project_code;
          this.ruleForm.customer_name = res.data.data[0].customer_name;
          this.ruleForm.delivery_code = res.data.data[0].delivery_code;
          this.ruleForm.invoice_code = res.data.data[0].invoice_code;
          this.ruleForm.distinguish = res.data.data[0].distinguish;
          this.ruleForm.classification = res.data.data[0].classification;
          this.ruleForm.invoice_date = res.data.data[0].invoice_date;
          this.ruleForm.note = res.data.data[0].note;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getEstimateDetailsDataAll() {
      let values = {};
      values["estimate_code"] = this.cInvoiceForm.estimate_code;
      getEstimateDetailsDataAll(values)
        .then(res => {
          let data = res.data.data;
          if (data) {
            data.forEach(row => {
              // 是否标记
              row["isEdit"] = false;
              // 是否新增
              row["isAdd"] = false;
            });
          }
          this.tableInvoiceData = data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    postInvoiceDetailsData(tableInvoiceData) {
      let values = {};
      values["index"] = tableInvoiceData.index;
      values["invoice_code"] = this.ruleForm.invoice_code;
      if (!tableInvoiceData.invoice_details_code) {
        values["invoice_details_code"] = NewProjectcode();
      } else {
        values["invoice_details_code"] = tableInvoiceData.invoice_details_code;
      }
      values["estimate_code"] = tableInvoiceData.estimate_code;
      values["estimate_details_code"] = tableInvoiceData.estimate_details_code;
      values["product_code"] = tableInvoiceData.product_code;
      values["product_name"] = tableInvoiceData.product_name;
      values["quantity"] = tableInvoiceData.quantity;
      values["price"] = tableInvoiceData.price;
      values["sub_total"] = tableInvoiceData.sub_total;
      values["tax"] = tableInvoiceData.tax;
      values["total"] = tableInvoiceData.total;
      values["remarks"] = tableInvoiceData.remarks;
      values["main_flag"] = tableInvoiceData.main_flag;
      values["created_by"] = this.$store.getters.full_name;
      postInvoiceDetailsData(values)
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getEstimateDatas() {
      let values = {};
      values["user_name"] = localStorage.getItem("user_name");
      getEstimateDatas(values)
        .then(res => {
          this.EstimateDataAll = res.data.data;
        })
        .catch(err => {
          console.log("2022-06-13 10:57:51 🏍☆…☆…☆…☆…☆…☆…:", err);
        });
    },
    getProductDataAll() {
      getProductDataAll()
        .then(res => {
          this.ProductsDataAll = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getInvoiceDetailsDataAll() {
      let values = {};
      values["invoice_code"] = this.cInvoiceForm.invoice_code;
      getInvoiceDetailsDataAll(values)
        .then(res => {
          let data = res.data.data;
          if (data) {
            data.forEach(row => {
              // 是否标记
              row["isEdit"] = false;
              // 是否新增
              row["isAdd"] = false;
            });
          }
          this.tableInvoiceData = data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    reLoadInvoiceDetailsData() {
      this.tableInvoiceData = [];
      // this.getInvoiceDetailsDataAll();
      for (let estimate_code of this.ruleForm.estimate_code) {
        let values = {};
        values["estimate_code"] = estimate_code;
        getEstimateDetailsDataAll(values)
          .then(res => {
            let data = res.data.data;
            if (data) {
              data.forEach(row => {
                // 是否标记
                row["isEdit"] = false;
                // 是否新增
                row["isAdd"] = false;
              });
              this.tableInvoiceData = this.tableInvoiceData.concat(data);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
      if (this.cInvoiceForm.method === "put") {
        let values = {};
        values["invoice_code"] = this.cInvoiceForm.invoice_code;
        getInvoiceDetailsDataAll(values)
          .then(res => {
            let data = res.data.data;
            if (data) {
              data.forEach(row => {
                // 是否标记
                row["isEdit"] = false;
                // 是否新增
                row["isAdd"] = false;
              });
            }
            this.tableInvoiceData = this.tableInvoiceData.concat(data);
          })
          .catch(err => {
            console.log(err);
          });
      }
      //从见积追加请求详细时立刻重置index
      this.tableInvoiceData.forEach((value, index) => {
        return (this.tableInvoiceData[index].index = index);
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "総計";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index === 2) {
            sums[index] = "";
          } else {
            sums[index] = sums[index]
              .toString()
              .replace(/(\d)(?=(\d{3})+$)/g, "$1,");
            sums[index] += "円";
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          switch (this.cInvoiceForm.method) {
            case "post":
              this.postInvoiceData();
              break;
            case "put":
              this.putInvoiceData();
              break;
            default:
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      console.log(this.tableInvoiceData);
      this.tableInvoiceData.forEach(value => {
        this.postInvoiceDetailsData(value);
      });
    },
    invoiceDrop() {
      const tbody = document.querySelector(".el-dialog__body tbody");
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableInvoiceData.splice(oldIndex, 1)[0];
          _this.tableInvoiceData.splice(newIndex, 0, currRow);
          _this.tableInvoiceData.forEach((value, index) => {
            return (_this.tableInvoiceData[index].index = index);
          });
        }
      });
    },
    cancelForm() {
      this.$emit("cancel");
    },
    querySearch(queryString, cb) {
      var EstimateDataAll = this.EstimateDataAll;
      var results = queryString
        ? EstimateDataAll.filter(this.createFilter(queryString))
        : EstimateDataAll;
      // 调用 callback 返回建议列表的数据

      // console.log(this.detailsForm.price)
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        // 从头开始匹配=== 0,全字段匹配> -1
        return (
          restaurant.estimate_code
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) > -1
        );
      };
    },
    handleSelect(item) {
      // 将选择的商品信息回传给data
      // this.cEstimateDetailsFormData.price = item.price
      // this.cEstimateDetailsFormData.ProductCode = item.id
      console.log(item);
    },
    handleIconClick(ev) {
      console.log(ev);
    }
  },
  mounted() {
    this.invoiceDrop();
  }
};
</script>

<style scoped>
</style>
