import { request } from 'network/request'

export function postOrderData(parameter) {
  return request({
    url: '/order',
    method: 'post',
    data: parameter
  })
}

export function putOrderData(parameter) {
  return request({
    url: '/order',
    method: 'put',
    data: parameter
  })
}

export function getOrderDataAll(parameter) {
  return request({
    url: '/order/all/' + parameter.project_code,
    method: 'get',
    data: parameter
  })
}

export function getOrderDataOne(parameter) {
  return request({
    url: '/order/one/' + parameter.order_code,
    method: 'get',
    data: parameter
  })
}

export function getInvoiceOrderPdf(parameter) {
  return request({
    url: '/order/pdf/invoiceorder/' + parameter.order_code,
    method: 'get',
    data: parameter
  })
}

export function getOrderPdf(parameter) {
  return request({
    url: '/order/pdf/order/' + parameter.order_code,
    method: 'get',
    data: parameter
  })
}
