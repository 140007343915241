<template>
  <div>
    <PageHeader>
      <template slot="title">戻る</template>
      <template slot="content">案件詳細</template>
    </PageHeader>
    <template>案件情報の登録及び編集する画面です。</template>
    <template
      >この画面で当案件の見積・注文・納品・検収・請求処理することができます。</template
    >
    <el-divider></el-divider>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <el-row :gutter="20">
        <el-col :span="16">
          <el-form-item prop="project_name">
            <el-input v-model="ruleForm.project_name" placeholder="案件名">
              <template slot="prepend">案件名</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item prop="customer_name">
            <el-autocomplete
              popper-class="my-autocomplete"
              v-model="ruleForm.customer_name"
              :fetch-suggestions="querySearch"
              placeholder="得意先"
              style="width: 100%"
              @select="handleSelect"
            >
              <i
                class="el-icon-edit el-input__icon"
                slot="suffix"
                @click="handleIconClick"
              >
              </i>
              <template slot-scope="{ item }">
                <span v-if="item.personnel_name" class="name">
                  {{ (item.value = item.customer_name) }} ({{
                    (item.key = item.personnel_name)
                  }})
                </span>
                <span v-else>
                  {{ (item.value = item.customer_name) }}
                </span>
              </template>
              <template slot="prepend">得意先</template>
            </el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="personnel_name">
            <el-input v-model="ruleForm.personnel_name" placeholder="担当者">
              <template slot="prepend">担当者</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-form-item prop="synopsis">
            <el-input v-model="ruleForm.synopsis" placeholder="案件詳細">
              <template slot="prepend">案件詳細</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="20" justify="start">
        <el-col :span="13">
          <el-form-item>
            <el-button-group>
              <el-button
                type="primary"
                @click="submitForm('ruleForm')"
                icon="el-icon-document-checked"
                plain
                >登録・更新
              </el-button>
              <el-button
                type="info"
                @click="createButtontoEstimateFormDialog('ruleForm')"
                icon="el-icon-document-add"
                plain
                >見積作成
              </el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!--    引入見積書・注文書・納品書・検収書・請求書tab组件-->
      <el-row :gutter="20">
        <el-col :span="24">
          <ProjectTabTable
            :c-table-data-estimate="tableDataEstimate"
            :c-table-data-order="tableDataOrder"
            :c-table-data-delivery="tableDataDelivery"
            :c-table-data-acceptance="tableDataAcceptance"
            :c-table-data-invoice="tableDataInvoice"
            @evEstimateForm="projectTabTabletoEstimateFormDialog(arguments)"
            @evOrderForm="toOrderFormDialog(arguments)"
            @evDeliveryForm="toDeliveryFormDialog(arguments)"
            @evAcceptanceForm="toAcceptanceFormDialog(arguments)"
            @evInvoiceForm="toInvoicrFormDialog(arguments)"
            @evPdfPreview="toPdfPreview(arguments)"
            @evReload="reloadList"
          ></ProjectTabTable>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <el-dialog
        title="見積書データの登録・更新"
        :visible.sync="dialogEstimateFormVisible"
        v-if="dialogEstimateFormVisible"
        :close-on-click-modal="false"
        top="5%"
        width="80vw"
        destroy-on-close
      >
        <EstimateForm
          class="diaform"
          :c-estimate-form="toEstimateForm"
          @cancel="closeDialog"
          @submit="reloadList"
        ></EstimateForm>
      </el-dialog>
      <el-dialog
        title="注文書データの登録・更新"
        :visible.sync="dialogOrderFormVisible"
        v-if="dialogOrderFormVisible"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <OrderForm
          class="diaform"
          :c-order-form="toOrderForm"
          @cancel="closeDialog"
          @submit="reloadList"
        ></OrderForm>
      </el-dialog>
      <el-dialog
        title="納品書データの登録・更新"
        :visible.sync="dialogDeliveryFormVisible"
        v-if="dialogDeliveryFormVisible"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <DeliveryForm
          class="diaform"
          :c-delivery-form="toDeliveryForm"
          @cancel="closeDialog"
          @submit="reloadList"
        ></DeliveryForm>
      </el-dialog>
      <el-dialog
        title="検収書データの登録・更新"
        :visible.sync="dialogAcceptanceFormVisible"
        v-if="dialogAcceptanceFormVisible"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <AcceptanceForm
          class="diaform"
          :c-acceptance-form="toAcceptanceForm"
          @cancel="closeDialog"
          @submit="reloadList"
        ></AcceptanceForm>
      </el-dialog>
      <el-dialog
        title="請求書データの登録・更新"
        :visible.sync="dialogInvoiceFormVisible"
        v-if="dialogInvoiceFormVisible"
        :close-on-click-modal="false"
        width="80vw"
        destroy-on-close
      >
        <InvoiceForm
          class="diaform"
          :c-invoice-form="toInvoiceForm"
          @cancel="closeDialog"
          @submit="reloadList"
        ></InvoiceForm>
      </el-dialog>
      <el-dialog
        class="pdfPlayView"
        :visible.sync="dialogPdfViewVisible"
        v-if="dialogPdfViewVisible"
        top="5%"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <!-- <a-icon type="cloud-download" /> <a-icon type="printer" /> -->
        <PdfView :c-pdf-view="toPdfView"></PdfView>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getCustomerDataAll } from "api/customers";
import { getEstimateDataAll } from "api/estimates";
import {
  getProjectDataOne,
  postProjectData,
  putProjectData
} from "api/projects";
import { getOrderDataAll } from "api/order";
import { getDeliveryDataAll } from "api/delivery";
import { getAcceptanceDataAll } from "api/acceptance";
import { getInvoiceDataAll } from "api/invoice";
import { NewProjectcode } from "@/utils/code_util";
import PageHeader from "components/common/menu/PageHeader";
import ProjectTabTable from "components/content/project/ProjectTabTable";
import EstimateForm from "@/views/project/EstimateForm";
import OrderForm from "@/views/project/OrderForm";
import DeliveryForm from "@/views/project/DeliveryForm";
import AcceptanceForm from "@/views/project/AcceptanceForm";
import InvoiceForm from "@/views/project/InvoiceForm";
import PdfView from "@/views/project/PdfView";
import { Message } from "element-ui";

export default {
  name: "ProjectForm",
  components: {
    PageHeader,
    ProjectTabTable,
    EstimateForm,
    OrderForm,
    DeliveryForm,
    AcceptanceForm,
    InvoiceForm,
    PdfView
  },
  data() {
    return {
      dialogEstimateFormVisible: false,
      dialogOrderFormVisible: false,
      dialogDeliveryFormVisible: false,
      dialogAcceptanceFormVisible: false,
      dialogInvoiceFormVisible: false,
      dialogPdfViewVisible: false,
      restaurants: [],
      Customers: [],
      ruleForm: {
        project_code: "",
        project_name: "",
        customer_name: "",
        customer_code: "",
        personnel_name: "",
        synopsis: ""
      },
      tableDataEstimate: [{}],
      tableDataOrder: [{}],
      tableDataDelivery: [{}],
      tableDataAcceptance: [{}],
      tableDataInvoice: [{}],
      toEstimateForm: {},
      toOrderForm: {},
      toDeliveryForm: {},
      toAcceptanceForm: {},
      toInvoiceForm: {},
      toPdfView: {},
      rules: {
        project_name: [
          {
            required: true,
            message: "案件名を入力してください",
            trigger: "blur"
          }
        ],
        customer_name: [
          {
            required: true,
            message: "得意先を選びまたは入力してください",
            trigger: "change"
          }
        ],
        synopsis: [
          {
            required: true,
            message: "案件詳細を入力してください",
            trigger: "blur"
          }
          // { min: 20, max: 300, message: '20~300文字まで入力してください', trigger: 'blur' }
        ]
      }
    };
  },
  created: function() {
    this.getCustomerDataAll();
    this.getEstimateDataAll();
    this.getOrderDataAll();
    this.getDeliveryDataAll();
    this.getAcceptanceDataAll();
    this.getInvoiceDataAll();
    if (this.$route.query.project_code) {
      this.getProjectDataOne();
    } else {
      this.ruleForm.project_code = NewProjectcode();
    }
  },
  computed: {
    newDateTime() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate =
        year + "-" + month + "-" + strDate + " " + hours + ":" + minutes;

      return currentdate;
    }
  },
  methods: {
    getCustomerDataAll() {
      getCustomerDataAll()
        .then(res => {
          this.Customers = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getProjectDataOne() {
      let values = {};
      values["project_code"] = this.$route.query.project_code;
      getProjectDataOne(values)
        .then(res => {
          this.ruleForm.project_code = res.data.data[0].project_code;
          this.ruleForm.project_name = res.data.data[0].project_name;
          this.ruleForm.customer_name = res.data.data[0].customer_name;
          this.ruleForm.personnel_name = res.data.data[0].personnel_name;
          this.ruleForm.synopsis = res.data.data[0].synopsis;
          this.ruleForm.customer_code = res.data.data[0].customer_code;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getEstimateDataAll() {
      let values = {};
      values["project_code"] = this.$route.query.project_code;
      getEstimateDataAll(values)
        .then(res => {
          console.log("見積データ", res);
          this.tableDataEstimate = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getOrderDataAll() {
      let values = {};
      values["project_code"] = this.$route.query.project_code;
      getOrderDataAll(values)
        .then(res => {
          this.tableDataOrder = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getDeliveryDataAll() {
      let values = {};
      values["project_code"] = this.$route.query.project_code;
      getDeliveryDataAll(values)
        .then(res => {
          this.tableDataDelivery = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getAcceptanceDataAll() {
      let values = {};
      values["project_code"] = this.$route.query.project_code;
      getAcceptanceDataAll(values)
        .then(res => {
          this.tableDataAcceptance = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getInvoiceDataAll() {
      let values = {};
      values["project_code"] = this.$route.query.project_code;
      getInvoiceDataAll(values)
        .then(res => {
          this.tableDataInvoice = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    postProjectData() {
      let values = {};
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["customer_code"] = this.ruleForm.customer_code;
      values["personnel_name"] = this.ruleForm.personnel_name;
      values["synopsis"] = this.ruleForm.synopsis;
      values["created_by"] = this.$store.getters.full_name;
      postProjectData(values)
        .then(res => {
          console.log(res);
          // 确保迁移到下一个画面数据是提交之后的
          if (res.data.status == 1) {
            // this.$router.push({ path: "ProjectList" });
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    putProjectData() {
      let values = {};
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["customer_code"] = this.ruleForm.customer_code;
      values["personnel_name"] = this.ruleForm.personnel_name;
      values["synopsis"] = this.ruleForm.synopsis;
      values["modified_by"] = this.$store.getters.full_name;
      putProjectData(values)
        .then(res => {
          console.log(res);
          // 确保迁移到下一个画面数据是提交之后的
          if (res.data.status == 1) {
            // this.$router.push({ path: "ProjectList" });
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.$route.query.project_code) {
            this.putProjectData();
          } else {
            this.postProjectData();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
          this.postProjectData(1);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      this.$router.push({ path: "ProjectList" });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields()
    // },
    projectTabTabletoEstimateFormDialog(param) {
      this.dialogEstimateFormVisible = true;
      this.toEstimateForm = {
        project_code: this.ruleForm.project_code,
        project_name: this.ruleForm.project_name,
        customer_name: this.ruleForm.customer_name,
        customer_code: this.ruleForm.customer_code,
        estimate_code: param[0].estimate_code,
        method: "put"
      };
    },
    createButtontoEstimateFormDialog(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.$route.query.project_code) {
            //   this.putProjectData();
            // } else {
            this.postProjectData();
          }
          this.dialogEstimateFormVisible = true;
          this.toEstimateForm = {
            project_code: this.ruleForm.project_code,
            project_name: this.ruleForm.project_name,
            customer_name: this.ruleForm.customer_name,
            customer_code: this.ruleForm.customer_code,
            estimate_code: NewProjectcode(),
            estimate_name: this.ruleForm.project_name,
            method: "post"
          };
        }
      });
    },
    toOrderFormDialog(param) {
      this.dialogOrderFormVisible = true;
      if (param[0].order_code) {
        this.toOrderForm = {
          project_code: this.ruleForm.project_code,
          project_name: this.ruleForm.project_name,
          customer_name: this.ruleForm.customer_name,
          customer_code: this.ruleForm.customer_code,
          personnel_name: this.ruleForm.personnel_name,
          // estimate_code: param[0].estimate_code,
          // estimate_name: param[0].estimate_name,
          // order_code: param[0].order_code,
          method: "put",
          ...param[0]
        };
      } else {
        this.toOrderForm = {
          project_code: this.ruleForm.project_code,
          project_name: this.ruleForm.project_name,
          customer_name: this.ruleForm.customer_name,
          customer_code: this.ruleForm.customer_code,
          personnel_name: this.ruleForm.personnel_name,
          // estimate_code: param[0].estimate_code,
          // estimate_name: param[0].estimate_name,
          order_code: NewProjectcode(),
          method: "post",
          ...param[0]
        };
      }
      console.log(...param);
    },
    toDeliveryFormDialog(param) {
      this.dialogDeliveryFormVisible = true;
      if (param[0].delivery_code) {
        this.toDeliveryForm = {
          project_code: this.ruleForm.project_code,
          project_name: this.ruleForm.project_name,
          customer_name: this.ruleForm.customer_name,
          customer_code: this.ruleForm.customer_code,
          estimate_code: param[0].estimate_code,
          delivery_code: param[0].delivery_code,
          method: "put"
        };
      } else {
        this.toDeliveryForm = {
          project_code: this.ruleForm.project_code,
          project_name: this.ruleForm.project_name,
          customer_name: this.ruleForm.customer_name,
          customer_code: this.ruleForm.customer_code,
          estimate_code: param[0].estimate_code,
          delivery_code: NewProjectcode(),
          method: "post"
        };
      }
    },
    toAcceptanceFormDialog(param) {
      this.dialogAcceptanceFormVisible = true;
      if (param[0].acceptance_code) {
        this.toAcceptanceForm = {
          project_code: this.ruleForm.project_code,
          project_name: this.ruleForm.project_name,
          customer_name: this.ruleForm.customer_name,
          customer_code: this.ruleForm.customer_code,
          estimate_code: param[0].estimate_code,
          acceptance_code: param[0].acceptance_code,
          method: "put"
        };
      } else {
        this.toAcceptanceForm = {
          project_code: this.ruleForm.project_code,
          project_name: this.ruleForm.project_name,
          customer_name: this.ruleForm.customer_name,
          customer_code: this.ruleForm.customer_code,
          estimate_code: param[0].estimate_code,
          delivery_code: param[0].delivery_code,
          acceptance_code: NewProjectcode(),
          method: "post"
        };
      }
    },
    toInvoicrFormDialog(param) {
      this.dialogInvoiceFormVisible = true;
      if (param[0].invoice_code) {
        this.toInvoiceForm = {
          project_code: this.ruleForm.project_code,
          project_name: this.ruleForm.project_name,
          customer_name: this.ruleForm.customer_name,
          customer_code: this.ruleForm.customer_code,
          estimate_code: param[0].estimate_code,
          invoice_code: param[0].invoice_code,
          method: "put"
        };
      } else {
        this.toInvoiceForm = {
          project_code: this.ruleForm.project_code,
          project_name: this.ruleForm.project_name,
          customer_name: this.ruleForm.customer_name,
          customer_code: this.ruleForm.customer_code,
          estimate_code: param[0].estimate_code,
          delivery_code: param[0].delivery_code,
          invoice_code: NewProjectcode(),
          method: "post"
        };
      }
    },
    toPdfPreview(param) {
      this.dialogPdfViewVisible = true;
      this.toPdfView = {
        pdf_path: param[0].pdf_path
      };
    },
    closeDialog() {
      this.dialogEstimateFormVisible = false;
      this.dialogOrderFormVisible = false;
      this.dialogDeliveryFormVisible = false;
      this.dialogAcceptanceFormVisible = false;
      this.dialogInvoiceFormVisible = false;
    },
    reloadList() {
      this.dialogEstimateFormVisible = false;
      this.dialogOrderFormVisible = false;
      this.dialogDeliveryFormVisible = false;
      this.dialogAcceptanceFormVisible = false;
      this.dialogInvoiceFormVisible = false;
      this.getEstimateDataAll();
      this.getOrderDataAll();
      this.getDeliveryDataAll();
      this.getAcceptanceDataAll();
      this.getInvoiceDataAll();
    },
    evEstimateForm(param) {
      this.dialogEstimateFormVisible = true;
      console.log(param[0].uuidEstimate);
    },
    evOrderForm(param) {
      this.dialogOrderFormVisible = true;
      console.log(param[0].estimate_code);
    },
    querySearch(queryString, cb) {
      var Customers = this.Customers;
      var results = queryString
        ? Customers.filter(this.createFilter(queryString))
        : Customers;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.customer_name
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) > -1
        );
      };
    },
    handleSelect(item) {
      // 把选择的顾客名对应的顾客CD一并发送给后端
      this.ruleForm.customer_code = item.customer_code;
      this.ruleForm.personnel_name = item.personnel_name;
    },
    handleIconClick(ev) {
      console.log(ev);
    }
  }
};
</script>

<style scoped>
.diaform {
  padding: 10px;
  border: 2px solid;
  border-radius: 10px;
}
.pdfPlayView {
  position: fixed;
  left: 20%;
  height: 170vh;
  width: 120vh;
}
</style>
