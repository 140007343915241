<template>
  <div class="main">
    <pdf class="pdfstyle" ref="wrapper" style="width: 100%" :src="url"></pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  name: "PdfView",
  props: {
    cPdfView: {
      type: Object, //指定传入的类型,type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: function() {
        //这样可以指定默认的值
        return {
          pdf_path: ""
        };
      }
    }
  },
  components: {
    pdf
  },
  data() {
    return {
      scale: 50,
      url: process.env.VUE_APP_URL + "/download/" + this.cPdfView.pdf_path
      // url: "https://bridge-sms.com/download/" + this.cPdfView.pdf_path
      // url: "http://192.168.3.107/download/" + this.cPdfView.pdf_path
      // url: "http://192.168.1.7/download/" + this.cPdfView.pdf_path
    };
  },
  methods: {
    scaleD() {
      this.scale += 5;
      this.$refs.wrapper.$el.style.width = parseInt(this.scale) + "%";
    },

    scaleX() {
      if (this.scale == 50) {
        return;
      }
      this.scale += -5;
      this.$refs.wrapper.$el.style.width = parseInt(this.scale) + "%";
    }
  }
};
</script>

<style>
.main {
  overflow: hidden;
}
.footers {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  color: #333;
  border-top: 1px solid #f0f0f0;
  line-height: 10px;
  height: 30px;
}
.li {
  text-align: center;
  flex: 1;
  padding: 5px;
  cursor: pointer;
}
.ul {
  list-style: none;
}
.pdfstyle {
  margin: 0;
  padding: 20px;
  border: 2px solid rgba(164, 21, 22);
  border-radius: 20px;
}
</style>
