<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    class="demo-ruleForm"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item prop="project_name">
          <el-input v-model="ruleForm.project_name" :disabled="true">
            <template slot="prepend">案件名</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item prop="estimate_name">
          <el-input v-model="ruleForm.estimate_name">
            <template slot="prepend">件名（業務名）</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="estimate_of_order">
          <el-input v-model="ruleForm.estimate_of_order">
            <template slot="prepend">標題の件につき</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <!--设置value-format可以防止axios发送post请求时修改时区-->
    <el-row :gutter="20">
      <el-col :span="12">
        <!--        value-format="yyyy-MM-dd"-->
        <el-form-item prop="invoice_order_date">
          <el-date-picker
            v-model="ruleForm.invoice_order_date"
            type="date"
            style="width: 100%"
            placeholder="注文請書日付"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="customer_name">
          <el-input v-model="ruleForm.customer_name">
            <template slot="prepend">得意先名</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="customer_address">
          <el-input v-model="ruleForm.customer_address">
            <template slot="prepend">得意先住所</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="work">
          <div>作業内容</div>
          <el-input
            v-model="ruleForm.work"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 6 }"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item prop="personnel1">
          <el-input v-model="ruleForm.personnel1">
            <template slot="prepend">顧客担当者</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item prop="personnel2">
          <el-input v-model="ruleForm.personnel2">
            <template slot="prepend">ブリッジ担当者</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="deliverables">
          <div>納入物</div>
          <el-input
            v-model="ruleForm.deliverables"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 6 }"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="commission">
          <div>委託料</div>
          <el-input
            v-model="ruleForm.commission"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 6 }"
          >
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="work_time">
          <el-input v-model="ruleForm.work_time">
            <template slot="prepend">作業期間/納期</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="deliverable_space">
          <el-input v-model="ruleForm.deliverable_space">
            <template slot="prepend">納入場所</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="payment_date">
          <el-autocomplete
            class="inline-input"
            v-model="ruleForm.payment_date"
            :fetch-suggestions="querySearchPaymentDate"
            placeholder="支払期日を選択"
            style="width: 100%"
          >
            <template slot="prepend">支払期日</template>
          </el-autocomplete>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="acceptance_conditions">
          <el-input v-model="ruleForm.acceptance_conditions">
            <template slot="prepend">検収条件</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="other">
          <div>その他</div>
          <el-input v-model="ruleForm.other" type="textarea" :rows="2">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="" prop="note">
          <div>※(注)</div>
          <el-input v-model="ruleForm.note" type="textarea" :rows="2">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item>
          <el-button-group>
            <el-button
              type="warning"
              @click="cancelForm()"
              icon="el-icon-document-delete"
              plain
              >閉じる</el-button
            >
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              icon="el-icon-document-checked"
              plain
              >登録・更新</el-button
            >
          </el-button-group>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { postOrderData, putOrderData, getOrderDataOne } from "api/order";
import { getCustomerDataOne } from "api/customers";
import { Message } from "element-ui";

export default {
  name: "OrderForm",
  components: {},
  props: {
    cOrderForm: {
      type: Object,
      default: function() {
        return {
          project_name: "",
          project_code: "",
          customer_name: "",
          personnel_name: "",
          estimate_code: "",
          estimate_name: "",
          order_code: "",
          method: ""
        };
      }
    }
  },
  data() {
    return {
      ruleForm: {
        restaurants_PaymentDate: [],
        order_code: this.cOrderForm.order_code,
        project_name: this.cOrderForm.project_name,
        project_code: this.cOrderForm.project_code,
        estimate_code: this.cOrderForm.estimate_code,
        estimate_name: this.cOrderForm.estimate_name,
        customer_name: this.cOrderForm.customer_name,
        estimate_of_order: "以下の通り注文いたします。",
        customer_address: "",
        work: this.cOrderForm.work3
          ? this.cOrderForm.work1 +
            "\n" +
            this.cOrderForm.work2 +
            "\n" +
            this.cOrderForm.work3
          : this.cOrderForm.work2
          ? this.cOrderForm.work1 + "\n" + this.cOrderForm.work2
          : this.cOrderForm.work1,
        work_time: "別途相談",
        personnel1: this.cOrderForm.personnel_name,
        personnel2: "",
        deliverables: this.cOrderForm.deliverables3
          ? this.cOrderForm.deliverables1 +
            "\n" +
            this.cOrderForm.deliverables2 +
            "\n" +
            this.cOrderForm.deliverables3
          : this.cOrderForm.deliverables2
          ? this.cOrderForm.deliverables1 + "\n" + this.cOrderForm.deliverables2
          : this.cOrderForm.deliverables1,
        deliverable_space: "指定場所",
        commission:
          this.cOrderForm.initial_total !== "0" &&
          this.cOrderForm.running_total !== "0"
            ? "　　初期費用　￥" +
              this.cOrderForm.initial_total +
              "（消費税別）" +
              "\n" +
              "　　　　月額　￥" +
              this.cOrderForm.running_total +
              "（消費税別）"
            : this.cOrderForm.initial_total !== "0"
            ? "　　初期費用　￥" +
              this.cOrderForm.initial_total +
              "（消費税別）"
            : "　　　　月額　￥" +
              this.cOrderForm.running_total +
              "（消費税別）",
        payment_date: "",
        acceptance_conditions: "",
        other: "見積書No." + this.cOrderForm.estimate_pdf_num + "に基づく。",
        note: "",
        invoice_order_date: new Date()
      },
      rules: {
        invoice_order_date: [
          {
            required: true,
            message: "注文請書を選択してください",
            trigger: "change"
          }
        ],
        customer_name: [
          {
            required: true,
            message: "得意先名を入力してください",
            trigger: "change"
          }
        ],
        customer_address: [
          {
            required: true,
            message: "得意先住所を入力してください",
            trigger: "blur"
          }
        ],
        work: [
          {
            required: true,
            message: "作業内容を入力してください",
            trigger: "blur"
          }
        ],
        work_time: [
          {
            required: true,
            message: "作業期間を入力してください",
            trigger: "blur"
          }
        ],
        personnel1: [
          {
            required: true,
            message: "主任担当者を入力してください",
            trigger: "blur"
          }
        ],
        // personnel2: [
        //   {
        //     required: true,
        //     message: "主任担当者(乙)を入力してください",
        //     trigger: "blur",
        //   },
        // ],
        deliverables: [
          {
            required: true,
            message: "成果物を入力してください",
            trigger: "blur"
          }
        ],
        deliverable_space: [
          {
            required: true,
            message: "納入場所を入力してください",
            trigger: "blur"
          }
        ],
        commission: [
          {
            required: true,
            message: "委託料を入力してください",
            trigger: "blur"
          }
        ],
        payment_date: [
          {
            required: true,
            message: "支払期日を入力してください",
            trigger: "change"
          }
        ],
        acceptance_conditions: [
          {
            required: true,
            message: "検収条件を入力してください",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created: function() {
    if (this.cOrderForm.method === "put") {
      this.getOrderDataOne();
    } else {
      this.getCustomerDataOne();
    }
  },
  methods: {
    postOrderData() {
      let values = {};
      values["order_code"] = this.ruleForm.order_code;
      values["project_name"] = this.ruleForm.project_name;
      values["project_code"] = this.ruleForm.project_code;
      values["estimate_code"] = this.ruleForm.estimate_code;
      values["estimate_name"] = this.ruleForm.estimate_name;
      values["estimate_of_order"] = this.ruleForm.estimate_of_order;
      values["customer_name"] = this.ruleForm.customer_name;
      values["customer_address"] = this.ruleForm.customer_address;
      values["work"] = this.ruleForm.work;
      values["work_time"] = this.ruleForm.work_time;
      values["personnel1"] = this.ruleForm.personnel1;
      values["personnel2"] = this.ruleForm.personnel2;
      values["deliverables"] = this.ruleForm.deliverables;
      values["deliverable_space"] = this.ruleForm.deliverable_space;
      values["commission"] = this.ruleForm.commission;
      values["payment_date"] = this.ruleForm.payment_date;
      values["acceptance_conditions"] = this.ruleForm.acceptance_conditions;
      values["other"] = this.ruleForm.other;
      values["note"] = this.ruleForm.note;
      values["invoice_order_date"] = this.ruleForm.invoice_order_date;
      values["created_by"] = this.$store.getters.full_name;
      postOrderData(values)
        .then(res => {
          if (res.data.status === 1) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    putOrderData() {
      let values = {};
      values["order_code"] = this.ruleForm.order_code;
      values["project_name"] = this.ruleForm.project_name;
      values["project_code"] = this.ruleForm.project_code;
      values["estimate_code"] = this.ruleForm.estimate_code;
      values["estimate_name"] = this.ruleForm.estimate_name;
      values["estimate_of_order"] = this.ruleForm.estimate_of_order;
      values["customer_name"] = this.ruleForm.customer_name;
      values["customer_address"] = this.ruleForm.customer_address;
      values["work"] = this.ruleForm.work;
      values["work_time"] = this.ruleForm.work_time;
      values["personnel1"] = this.ruleForm.personnel1;
      values["personnel2"] = this.ruleForm.personnel2;
      values["deliverables"] = this.ruleForm.deliverables;
      values["deliverable_space"] = this.ruleForm.deliverable_space;
      values["commission"] = this.ruleForm.commission;
      values["payment_date"] = this.ruleForm.payment_date;
      values["acceptance_conditions"] = this.ruleForm.acceptance_conditions;
      values["other"] = this.ruleForm.other;
      values["note"] = this.ruleForm.note;
      values["invoice_order_date"] = this.ruleForm.invoice_order_date;
      values["modified_by"] = this.$store.getters.full_name;
      putOrderData(values)
        .then(res => {
          if (res.data.status === 1) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getOrderDataOne() {
      let values = {};
      values["order_code"] = this.cOrderForm.order_code;
      getOrderDataOne(values)
        .then(res => {
          this.ruleForm.order_code = this.cOrderForm.order_code;
          this.ruleForm.project_name = res.data.data[0].project_name;
          this.ruleForm.project_code = res.data.data[0].project_code;
          this.ruleForm.estimate_code = res.data.data[0].estimate_code;
          this.ruleForm.estimate_name = res.data.data[0].estimate_name;
          this.ruleForm.estimate_of_order = res.data.data[0].estimate_of_order;
          this.ruleForm.customer_name = res.data.data[0].customer_name;
          this.ruleForm.customer_address = res.data.data[0].customer_address;
          this.ruleForm.work = res.data.data[0].work;
          this.ruleForm.work_time = res.data.data[0].work_time;
          this.ruleForm.personnel1 = res.data.data[0].personnel1;
          this.ruleForm.personnel2 = res.data.data[0].personnel2;
          this.ruleForm.deliverables = res.data.data[0].deliverables;
          this.ruleForm.deliverable_space = res.data.data[0].deliverable_space;
          this.ruleForm.commission = res.data.data[0].commission;
          this.ruleForm.payment_date = res.data.data[0].payment_date;
          this.ruleForm.acceptance_conditions =
            res.data.data[0].acceptance_conditions;
          this.ruleForm.other = res.data.data[0].other;
          this.ruleForm.invoice_order_date =
            res.data.data[0].invoice_order_date;
          this.ruleForm.note = res.data.data[0].note;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCustomerDataOne() {
      let values = {};
      values["customer_code"] = this.cOrderForm.customer_code;
      getCustomerDataOne(values)
        .then(res => {
          this.ruleForm.customer_address =
            res.data.data[0].address1 +
            res.data.data[0].address2 +
            res.data.data[0].address3;
        })
        .catch(err => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.cOrderForm.method === "put") {
            this.putOrderData();
          } else {
            this.postOrderData();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 注文画面支付日期内容输入框自动检索
    querySearchPaymentDate(queryString, cb) {
      var restaurants_PaymentDate = this.restaurants_PaymentDate;
      var results = queryString
        ? restaurants_PaymentDate.filter(this.createFilter(queryString))
        : restaurants_PaymentDate;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1
        );
      };
    },
    loadPaymentDate() {
      return [
        {
          value: "請求書を元に月末締め翌月末日までに銀行振込"
        },
        {
          value: "請求書を元に２０日締め翌月末日までに銀行振込"
        }
      ];
    },
    cancelForm() {
      this.$emit("cancel");
    }
  },
  mounted() {
    this.restaurants_PaymentDate = this.loadPaymentDate();
  }
};
</script>

<style scoped>
.el-dialog {
  border: 2px solid;
  border-radius: 20px;
}
</style>
